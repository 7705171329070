import React, { useEffect, useState } from "react";
import { Button, Col, Input, Row, Select } from "antd";
import { getCountries } from "../../../Utils/Helpers";
import Hyphen from "../../../Assets/Images/Hyphen.svg";
import Close_black_icon from "../../../Assets/Images/close_black_icon.svg";
import ArrowUpAndDownIcon from "../../../Assets/Images/SVGs/ArrowLineUpDown.svg";

import { setTranchFilter } from "../../../Redux/Action/Dashboard";
import { useDispatch } from "react-redux";
import Percent from "../../../Assets/Images/Percent.svg";
import { formatString, getCountryNameByCode } from "../../../Utils/Reusables";
import "./style.css";

const CommonFilter = ({ trancheCountry, newTranchList }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(null);
  const [activeDealTab, setActiveDealTab] = useState(null);
  const [activeRatingTab, setActiveRatingTab] = useState(null);
  const countryList = getCountries();
  const matchingObjects = countryList?.filter((obj) =>
    trancheCountry?.includes(obj.key)
  );

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterData, setFilterData] = useState({
    fromInterest: "",
    toInterest: "",
    currency: "ALL",
    industry: "",
    EarlyRedemptionFrequency: "",
    country: "",
    dealStatus: "all",
    creditRating: "",
  });
  const filteredSelectedFilters = selectedFilters?.filter(
    (filter) =>
      filter?.val !== undefined && filter?.val !== null && filter?.val !== "-"
  );

  useEffect(() => {
    setTranchFilter({ data: filterData }, dispatch);
  }, [dispatch, filterData]);

  useEffect(() => {
    setFilterData(filterData);
    setActiveTab(filterData?.currency || null);
    setActiveDealTab(filterData?.dealStatus || null);
    setActiveRatingTab(filterData?.creditRating || null);
  }, [filterData]);

  useEffect(() => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = [];
      if (filterData?.fromInterest !== undefined) {
        updatedFilters.push({
          key: "Interest Rate",
          val: `${filterData?.fromInterest}-${filterData?.toInterest}`,
        });
      }
      if (filterData?.currency !== undefined) {
        updatedFilters.push({
          key: "Currency",
          val: filterData?.currency,
        });
      }
      if (filterData?.industry !== undefined) {
        updatedFilters.push({
          key: "Industry",
          val: filterData?.industry,
        });
      }
      if (filterData?.EarlyRedemptionFrequency !== undefined) {
        updatedFilters.push({
          key: "Minimum holding period",
          val: filterData?.EarlyRedemptionFrequency,
        });
      }
      if (filterData?.country !== undefined) {
        updatedFilters.push({
          key: "Country",
          val: getCountryNameByCode(countryList, filterData?.country),
        });
      }
      if (filterData?.dealStatus !== undefined) {
        updatedFilters.push({
          key: "Deal Status",
          val: filterData?.dealStatus,
        });
      }
      if (filterData?.creditRating !== undefined) {
        updatedFilters.push({
          key: "Credit Rating",
          val: filterData?.creditRating,
        });
      }
      return updatedFilters;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    if (["fromInterest", "toInterest"].includes(name) && value > 100) {
      return;
    }

    const updatedFilterData = { ...filterData, [name]: value };
    setFilterData(updatedFilterData);
    await setTranchFilter({ data: updatedFilterData }, dispatch);
  };

  const handleChangeCurrency = async (e) => {
    let updatedFilterData;
    if (filterData.currency === e) {
      setActiveTab(null);
      updatedFilterData = { ...filterData, currency: "" };
    } else {
      setActiveTab(e);
      updatedFilterData = { ...filterData, currency: e };
    }
    setFilterData(updatedFilterData);
    await setTranchFilter({ data: updatedFilterData }, dispatch);
  };

  const handleChangeDealStatus = async (e) => {
    let updatedFilterData;
    setActiveDealTab(e);
    updatedFilterData = { ...filterData, dealStatus: e };
    setFilterData(updatedFilterData);
    await setTranchFilter({ data: updatedFilterData }, dispatch);
  };

  const handleChangeRatingStatus = async (e) => {
    let updatedFilterData;
    if (filterData.creditRating === e) {
      setActiveRatingTab(null);
      updatedFilterData = { ...filterData, creditRating: "" };
    } else {
      setActiveRatingTab(e);
      updatedFilterData = { ...filterData, creditRating: e };
    }
    setFilterData(updatedFilterData);
    await setTranchFilter({ data: updatedFilterData }, dispatch);
  };

  const handleIndustry = async (e) => {
    const value = e || "";
    const updatedFilterData = {
      ...filterData,
      industry: value,
    };
    setFilterData(updatedFilterData);
    await setTranchFilter({ data: updatedFilterData }, dispatch);
  };

  const handleCountry = async (e) => {
    const value = e?.key || "";
    const updatedFilterData = { ...filterData, country: value };

    setFilterData(updatedFilterData);
    setTranchFilter({ data: updatedFilterData }, dispatch);
  };

  const handleFrequency = async (e) => {
    const updatedFrequency = e === undefined ? "" : e;
    const newFilterData = {
      ...filterData,
      EarlyRedemptionFrequency: updatedFrequency,
    };
    setFilterData(newFilterData);
    await setTranchFilter({ data: newFilterData }, dispatch);
  };

  const handleFilterClose = (filter, filterIndex) => {
    setSelectedFilters((prevFilters) =>
      prevFilters.filter((_, index) => index !== filterIndex)
    );

    setFilterData((prevFilterData) => {
      const updatedFilterData = { ...prevFilterData };

      if (filter.key === "Interest Rate") {
        updatedFilterData.fromInterest = "";
        updatedFilterData.toInterest = "";
      } else if (filter.key === "Minimum holding period") {
        updatedFilterData.EarlyRedemptionFrequency = "";
      } else if (filter.key === "Country") {
        updatedFilterData.country = "";
      } else if (filter.key === "Industry") {
        updatedFilterData.industry = "";
      } else if (filter.key === "Credit Rating") {
        updatedFilterData.creditRating = "";
      } else {
        updatedFilterData[filter.key] = "";
      }

      return updatedFilterData;
    });

    setTranchFilter({ data: filterData }, dispatch);
  };

  const handleClearAll = () => {
    const resetFilterData = {
      fromInterest: "",
      toInterest: "",
      currency: "ALL",
      industry: "",
      EarlyRedemptionFrequency: "",
      country: "",
      dealStatus: "all",
      creditRating: "",
    };

    setFilterData(resetFilterData);
    setSelectedFilters(
      selectedFilters.filter((filter) => filter.key === "Deal Status")
    );
    setSelectedFilters(
      selectedFilters.filter((filter) => filter.key === "Currency")
    );
    setTranchFilter(
      {
        data: resetFilterData,
      },
      dispatch
    );
  };

  const handleKeyDown = (event) => {
    const charCode = event.keyCode;
    if (
      (charCode < 48 || charCode > 57) &&
      (charCode < 96 || charCode > 105) &&
      charCode !== 8 &&
      charCode !== 46 &&
      charCode !== 9 &&
      charCode !== 27 &&
      charCode !== 13 &&
      charCode !== 110 &&
      charCode !== 190
    ) {
      event.preventDefault();
    }
  };

  const handleInput = (event) => {
    const value = event.target.value;
    if (!/^\d*\.?\d*$/.test(value)) {
      handleChange({
        target: { name: "toInterest", value: value.replace(/[^\d.]/g, "") },
      });
      handleChange({
        target: { name: "fromInterest", value: value.replace(/[^\d.]/g, "") },
      });
    }
  };

  return (
    <div>
      <Row className="filter-modal">
        <Col sx={24} className="modal-filtered-data">
          {filteredSelectedFilters?.length > 0 && (
            <Col sx={24} className="w-100">
              <div className="sb-flex-justify-between align-center mb-15">
                <p className="modal-title m-0">Filters</p>
                {selectedFilters.some(
                  (filter) =>
                    (filter.key === "Interest Rate" ||
                      filter.key === "Industry" ||
                      filter.key === "Minimum holding period" ||
                      filter.key === "Country" ||
                      filter.key === "Credit Rating") &&
                    filter.val &&
                    filter.val !== null &&
                    filter.val !== "" &&
                    filter.val !== "-"
                ) && (
                  <p
                    className="modal-subtitle m-0 cursor-pointer"
                    onClick={handleClearAll}
                  >
                    Clear All
                  </p>
                )}
              </div>
            </Col>
          )}

          {filteredSelectedFilters?.length > 0 &&
            filteredSelectedFilters?.map((filter, index) => (
              <Row key={index} className="gutter-row">
                <Col sx={24}>
                  {filter?.val && (
                    <div className="filterlist">
                      <span className="filterData">
                        {filter.key}: {filter.val}
                      </span>
                      {filter.key !== "Deal Status" &&
                        filter.key !== "Currency" && (
                          <img
                            src={Close_black_icon}
                            alt="close_icon"
                            onClick={() => handleFilterClose(filter, index)}
                            style={{ marginLeft: 5, cursor: "pointer" }}
                          />
                        )}
                    </div>
                  )}
                </Col>
              </Row>
            ))}
        </Col>
      </Row>
      <div className="filter-media-input-div">
        <p className="filter-subtitle">Deal Status</p>
        <Row className="currency-filter-div">
          <Button
            onClick={() => handleChangeDealStatus("all")}
            className={activeDealTab === "all" ? "active-tab" : null}
          >
            All
          </Button>
          {newTranchList?.length > 0 ? (
            <Button
              onClick={() => handleChangeDealStatus("new")}
              className={activeDealTab === "new" ? "active-tab" : null}
            >
              New
            </Button>
          ) : null}
          <Button
            onClick={() => handleChangeDealStatus("active")}
            className={activeDealTab === "active" ? "active-tab" : null}
          >
            Active
          </Button>
          <Button
            onClick={() => handleChangeDealStatus("past")}
            className={activeDealTab === "past" ? "active-tab" : null}
          >
            Past
          </Button>
        </Row>
        <p className="filter-subtitle">Rating</p>
        <Row className="currency-filter-div">
          <Button
            onClick={() => handleChangeRatingStatus("A")}
            className={activeRatingTab === "A" ? "active-tab" : null}
          >
            A
          </Button>
          <Button
            onClick={() => handleChangeRatingStatus("B")}
            className={activeRatingTab === "B" ? "active-tab" : null}
          >
            B
          </Button>
          <Button
            onClick={() => handleChangeRatingStatus("C")}
            className={activeRatingTab === "C" ? "active-tab" : null}
          >
            C
          </Button>
          <Button
            onClick={() => handleChangeRatingStatus("D")}
            className={activeRatingTab === "D" ? "active-tab" : null}
          >
            D
          </Button>
        </Row>
        <p className="filter-subtitle mt-24 ">Currency</p>
        <Row className="currency-filter-div">
          <Button
            onClick={() => handleChangeCurrency("ALL")}
            className={
              activeTab === "ALL" || activeTab === "" || activeTab === null
                ? "active-tab"
                : null
            }
          >
            ALL
          </Button>
          <Button
            onClick={() => handleChangeCurrency("USD")}
            className={activeTab === "USD" ? "active-tab" : null}
          >
            USD
          </Button>
          <Button
            onClick={() => handleChangeCurrency("SGD")}
            className={activeTab === "SGD" ? "active-tab" : null}
          >
            SGD
          </Button>
          <Button
            onClick={() => handleChangeCurrency("EUR")}
            className={activeTab === "EUR" ? "active-tab" : null}
          >
            EUR
          </Button>
        </Row>
        <p className="filter-subtitle mt-24">Interest Rate</p>
        <Row gutter={10}>
          <Col xs={11} className="gutter-row">
            <div>
              <Input
                value={filterData?.fromInterest}
                placeholder="from"
                className="kl-filter-input"
                name="fromInterest"
                onChange={handleChange}
                style={{ position: "relative" }}
                type="number"
                onKeyDown={handleKeyDown}
                onInput={handleInput}
              />
              <img
                src={Percent}
                alt="percentage"
                style={{ position: "absolute", top: 10, right: 16 }}
              />
            </div>
          </Col>
          <Col xs={2} className="gutter-row" style={{ marginTop: 5 }}>
            <img src={Hyphen} alt="Hyphen" />
          </Col>
          <Col xs={11} className="gutter-row">
            <div>
              <Input
                value={filterData?.toInterest}
                placeholder="to"
                className="kl-filter-input"
                name="toInterest"
                onChange={handleChange}
                style={{ position: "relative" }}
                type="number"
                onKeyDown={handleKeyDown}
                onInput={handleInput}
              />
              <img
                src={Percent}
                alt="percentage"
                style={{ position: "absolute", top: 10, right: 16 }}
              />
            </div>
          </Col>
        </Row>
        <p className="filter-subtitle mt-24 ">Minimum holding period</p>
        <Row>
          <Col xs={24} className="gutter-row">
            <Select
              value={
                filterData?.EarlyRedemptionFrequency !== undefined &&
                filterData?.EarlyRedemptionFrequency !== null &&
                filterData?.EarlyRedemptionFrequency !== ""
                  ? filterData?.EarlyRedemptionFrequency
                  : undefined
              }
              suffixIcon={<img src={ArrowUpAndDownIcon} alt="arrow-icon" />}
              allowClear
              placeholder="Select Frequency (m)"
              onChange={handleFrequency}
              className="kd-filter-select"
              options={[
                { value: "YES", label: "Yes" },
                { value: "NO", label: "No" },
              ]}
            />
          </Col>
        </Row>
        <p className="filter-subtitle mt-24">Country</p>
        <Row>
          <Col className="gutter-row" xs={24}>
            <Select
              value={
                filterData?.country !== undefined &&
                filterData?.country !== null
                  ? matchingObjects.find(
                      (country) => country.key === filterData?.country
                    )?.value
                  : undefined
              }
              showSearch
              suffixIcon={<img src={ArrowUpAndDownIcon} alt="arrow-icon" />}
              allowClear
              placeholder="Select a Сountry"
              onChange={(value, key) => handleCountry(key)}
              className="kd-filter-select"
              options={matchingObjects}
            />
          </Col>
        </Row>
        <p className="filter-subtitle mt-24 ">Industry</p>
        <Row>
          <Col className="gutter-row" xs={24}>
            <Select
              value={
                filterData?.industry !== undefined &&
                filterData?.industry !== null &&
                filterData?.industry !== ""
                  ? filterData?.industry
                  : undefined
              }
              showSearch
              suffixIcon={<img src={ArrowUpAndDownIcon} alt="arrow-icon" />}
              allowClear
              placeholder="Select Industry"
              onChange={handleIndustry}
              className="kd-filter-select"
              options={[
                { value: "REAL_ESTATE", label: "Real Estate" },
                { value: "LENDING", label: "Lending" },
              ]}
            />
          </Col>
        </Row>
      </div>
      {filteredSelectedFilters?.length > 0 &&
        filteredSelectedFilters !== undefined && (
          <div className="custom-modal-overlay">
            <div className="sb-flex-justify-between align-center mb-15">
              <p className="modal-title m-0">Filters</p>

              {selectedFilters.some(
                (filter) =>
                  (filter.key === "Interest Rate" ||
                    filter.key === "Industry" ||
                    filter.key === "Minimum holding period" ||
                    filter.key === "Country" ||
                    filter.key === "Credit Rating") &&
                  filter.val &&
                  filter.val !== null &&
                  filter.val !== "" &&
                  filter.val !== "-"
              ) && (
                <p
                  className="modal-subtitle m-0 cursor-pointer"
                  onClick={handleClearAll}
                >
                  Clear All
                </p>
              )}
            </div>
            <Row className="show-filter-list">
              {filteredSelectedFilters?.length > 0 &&
                filteredSelectedFilters?.map((filter, index) => (
                  <div key={index} className="filter-item">
                    {filter?.val && (
                      <Row key={index} className="gutter-row">
                        <div className="filterlist">
                          <span className="filterData">
                            {filter.key}:{formatString(filter.val)}
                          </span>
                          {filter.key !== "Deal Status" &&
                            filter.key !== "Currency" && (
                              <img
                                src={Close_black_icon}
                                alt="close_icon"
                                onClick={() => handleFilterClose(filter, index)}
                                style={{ marginLeft: 5, cursor: "pointer" }}
                              />
                            )}
                        </div>
                      </Row>
                    )}
                  </div>
                ))}
            </Row>
          </div>
        )}
    </div>
  );
};
export default CommonFilter;
