import React, { useEffect, useState } from "react";
import FinishOnboardingModal from "../../../Layouts/DashboardLayout/FinishOnboardingModal";
import DashboardLayout from "../../../Layouts/DashboardLayout/DashboardLayout";
import Balance from "./Balance";
import "./Style.css";
import { Col, Row, Tabs } from "antd";
import Deposit from "./Deposit";
import AddBank from "./AddBank";
import Withdraw from "./Withdraw";
import { getUser } from "../../../Apis/UserApi";
import { setUserDetails } from "../../../Redux/Action/User";
import { useDispatch, useSelector } from "react-redux";
import CurrencyExchange from "./CurrencyExchange";
import FinishOnboarding from "../Investment/FinishOnboarding";
import ActiveUserBanner from "../../Settings/ActiveUserBanner";
import { useLocation, useNavigate } from "react-router-dom";
import qrcode from "../../../Assets/Images/paynowqr.svg";
import ButtonDefault from "../../../Components/ButtonDefault/ButtonDefault";
import { allowedUserIds } from "../../../Utils/Constant";

const Wallet = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState("");
  const [tabKey, setTabKey] = useState(
    localStorage.getItem("activeTabKey") || "1"
  );

  const user = useSelector((state) => state.user);
  const accountNo = useSelector(
    (state) => state?.wallet?.bankAccount?.bankAccounts
  );

  useEffect(() => {
    if (location?.state?.tabKey) {
      setTabKey(location.state.tabKey);
      localStorage.setItem("activeTabKey", location.state.tabKey);

      navigate(location.pathname, {
        replace: true,
        state: { ...location.state, tabKey: undefined },
      });
    }
  }, [location, navigate]);

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    const response = await getUser();
    if (response) {
      setUserDetails(response, dispatch);
      return response;
    }
  };

  const onChange = (key) => {
    setTabKey(key);
    localStorage.setItem("activeTabKey", key);
  };

  const handleDeposit = (data) => {
    setShow(data);
  };

  const items = [
    {
      key: "1",
      label: "Deposit",
      children: <Deposit sendData={handleDeposit} />,
    },
    {
      key: "2",
      label: "Withdraw",
      children: <Withdraw tabKey={tabKey} />,
    },
    {
      key: "3",
      label: "Currency Exchange",
      children: <CurrencyExchange />,
    },
  ];

  const handleDownload = () => {
    const imageUrl = qrcode;
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "paynow.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <DashboardLayout>
      <div className="wallet-maindiv">
        {user?.investorStatus !== "ACTIVE" ||
        (user?.investorStatus === "ACTIVE" && accountNo?.length <= 0) ||
        (user?.secondFactorAuth === null &&
          user?.twoFaCheckEnabled === true) ? (
          <div className="mb-24">
            <FinishOnboarding />
          </div>
        ) : null}
        {allowedUserIds.includes(user?.number) ? <ActiveUserBanner /> : null}
        <p className="mt-0 mb-24 setting-head">Wallet</p>
        <Row className="mt-24 media-borrower-b-row">
          <Balance />
        </Row>
        <Row className="mt-16 media-borrower-b-row">
          <AddBank />
        </Row>
        <div className="scanner-div">
          <Col
            className="gutter-row mt-40"
            xl={17}
            lg={18}
            md={22}
            sm={24}
            xs={24}
          >
            <Tabs
              activeKey={tabKey}
              items={items}
              onChange={onChange}
              className="wallet-tab"
            />
          </Col>
          <div className="qr-maindiv">
            {tabKey === "1" && show === "PayNow" && (
              <div className="qrdiv">
                <img src={qrcode} alt="qr" />
                <ButtonDefault title="Save image" onClick={handleDownload} />
              </div>
            )}
          </div>
        </div>
      </div>
      <FinishOnboardingModal
        title="Wallet"
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </DashboardLayout>
  );
};

export default Wallet;
