import React from "react";
import bannerImage from "../../Assets/Images/SVGs/voting.svg";
import closeIcon from "../../Assets/Images/closeIcon.svg";
import { useState } from "react";
import { PopupButton } from "@typeform/embed-react";
import { useSelector } from "react-redux";

const TwoFABanner = () => {
  const user = useSelector((state) => state?.user);

  const [show, setShow] = useState(true);

  return (
    <div>
      {show ? (
        <div className="twofa-banner-div">
          <div className="twofa-subdiv">
            <div
              style={{ color: "var(--kilde-blue)" }}
              className="twofa-secondsubdiv"
            >
              <img
                src={bannerImage}
                alt="2fa-banner"
                style={{ marginRight: "12px" }}
              />
              {window.innerWidth <= 576 && (
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "24px",
                  }}
                  className="mb-0 mt-0"
                >
                  Vote on proposed changes to Fluid tranche parameters
                </p>
              )}
            </div>
            <div className="twofa-thirdsubdiv">
              {window.innerWidth > 576 && (
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "24px",
                  }}
                  className="mb-0 mt-0"
                >
                  Vote on proposed changes to Fluid tranche parameters
                </p>
              )}

              <p
                className="mt-8 mb-0"
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                You're invested in a Fluid tranche. Please review the proposed
                changes and cast your vote. Already voted? You can ignore this
                message.
              </p>
            </div>
          </div>
          <PopupButton
            id="r7U8kKtg"
            hidden={{
              email: user?.email,
              name: user?.firstName + " " + user?.lastName,
              user_id: user?.number,
            }}
            className="typeform-share-link"
            size={80}
            onReady={() => console.log("Typeform is ready")}
          >
            Start Voting
          </PopupButton>
          <div
            style={{
              position: "absolute",
              top: "12px",
              right: "12px",
              cursor: "pointer",
            }}
            onClick={() => setShow(false)}
          >
            <img src={closeIcon} alt="closeIcon" />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TwoFABanner;
