import { Button, Col, Modal, QRCode } from "antd";
import React, { useEffect, useState } from "react";
import DeviceMobile from "../../Assets/Images/Icon2fa.svg";
import OtpInput from "react18-input-otp";
import {
  enableSMS,
  enableTOTP,
  getUser,
  setupSms,
  setupTotp,
} from "../../Apis/UserApi.js";
import GlobalVariabels from "../../Utils/GlobalVariabels.js";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault.jsx";
import { Link, useNavigate } from "react-router-dom";
import { setCurrentSate } from "../../Redux/Action/common.js";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../Redux/Action/User.js";
import ROUTES from "../../Config/Routes.js";
import twofaicon from "../../Assets/Images/IconSet2fa.svg";
import twofadone from "../../Assets/Images/twofa-done.svg";
import {
  showMessageWithCloseIcon,
  showMessageWithCloseIconError,
} from "../../Utils/Reusables.js";
import ReactLoading from "react-loading";
import SMS from "../../Assets/Images/twofaps.svg";
import Device from "../../Assets/Images/twofaga.svg";
import { ArrowLeftOutlined } from "@ant-design/icons";

const TwoFAModal = ({ twoFaModal, setTwoFaModal, setShouldNavigate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [time, setTime] = useState(59);
  const [setUpSMS, setSetUpSMS] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [tOtpLoader, setTotpLoader] = useState(false);
  const [totp, setTotp] = useState(false);
  const [qrUrl, setQrURL] = useState("");
  const [tOTPLoader, setTOTPLoader] = useState(false);
  const [otp, setOtp] = useState("");
  const [twoFaLoader, setTwoFaLoader] = useState(false);
  let currentEnv = GlobalVariabels.NODE_ENV;
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const current = useSelector((state) => state.common.current);

  const getUserDetails = async () => {
    try {
      const response = await getUser();
      if (response) {
        setUserDetails(response, dispatch);
        return response;
      } else {
        console.error("Error fetching user data:");
        return null;
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      return null;
    }
  };

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      if (isTimerRunning) {
        setTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(intervalTimer);
            setIsTimerRunning(false);
          }
          return prevTime - 1;
        });
      }
    }, 1000);

    return () => clearInterval(intervalTimer);
  }, [isTimerRunning]);

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTwoFaAuth = async () => {
    setTwoFaLoader(true);
    await setupSms()
      .then((res) => {
        showMessageWithCloseIcon(
          "We've sent an OTP to your mobile number. Please check your messages."
        );
        window?.dataLayer?.push({
          event: "click_SMS_Setup",
          user_id: user?.number,
        });
        setIsTimerRunning(true);
        setTime(59);
        setSetUpSMS(res);
        setTwoFaLoader(false);
      })
      .catch(() => {
        setTwoFaLoader(false);
      });
  };

  const handleEnableSMS = async () => {
    setTwoFaLoader(true);
    if (otp !== "") {
      const requestBody = {
        smsToken: otp,
      };
      const response = await enableSMS(requestBody);
      if (!response) {
        window?.dataLayer?.push({
          event: "authenticationTwoFactorConfirm",
          user_id: user?.number,
        });
        showMessageWithCloseIcon(
          "You've successfully enabled Two-Factor Authentication for your account."
        );
        await getUserDetails();
        setTwoFaLoader(false);
        setSetUpSMS(true);
        setIsTimerRunning(false);
      } else {
        setTwoFaLoader(false);
      }
    } else {
      setTwoFaLoader(false);
      showMessageWithCloseIconError("Please enter OTP!");
    }
  };

  const skipForNow = async () => {
    setTwoFaModal(false);
    window?.dataLayer?.push({
      event: "Iwill_doItLater",
      user_id: user?.number,
    });
    if (user?.singpassUser === true) {
      setShouldNavigate(true);
    } else {
      if (user?.investorType === "INDIVIDUAL") {
        setCurrentSate(current + 1, dispatch);
      } else {
        setCurrentSate(2, dispatch);
      }
    }
  };

  const handleContinue = async () => {
    setTwoFaModal(false);
    if (user?.singpassUser === true) {
      if (user?.investorType === "INDIVIDUAL") {
        navigate(ROUTES.INDIVIDUAL_VERIFICATION);
      } else {
        navigate(ROUTES.ORGANIZATION_VERIFICATION);
      }
    } else {
      if (user?.investorType === "INDIVIDUAL") {
        setCurrentSate(current + 1, dispatch);
      } else {
        setCurrentSate(2, dispatch);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const inputStyle = {
    borderRadius: "var(--12, 12px)",
    border: "1px solid var(--black-10, rgba(26, 32, 44, 0.10))",
    background: "var(--white-80, rgba(255, 255, 255, 0.80))",
    width: windowWidth <= 380 ? "35px" : "40px",
    marginLeft: "4px",
    marginRight: "4px",
    height: windowWidth <= 380 ? "35px" : "40px",
    fontSize: "14px",
    fontWeight: "400",
    padding: "var(--4, 4px) var(--12, 12px)",
    color: "var(--kilde-blue)",
  };

  const handleSetupTotp = async () => {
    setTotpLoader(true);
    await setupTotp()
      .then((res) => {
        if (res) {
          window?.dataLayer?.push({
            event: "click_GAuthenticator_Setup",
            user_id: user?.number,
          });
          setTotp("showqr");
          setQrURL(res?.registrationURL);
          setTotpLoader(false);
        }
      })
      .catch(() => {
        setTotpLoader(false);
      });
  };

  const handleAuthentication = async () => {
    setTOTPLoader(true);
    if (otp !== "") {
      const requestBody = {
        totpToken: otp,
      };
      const response = await enableTOTP(requestBody);
      if (!response) {
        await getUserDetails();
        showMessageWithCloseIcon(
          "You've successfully enabled Two-Factor Authentication for your account."
        );
        setTotp("completed");
        window?.dataLayer?.push({
          event: "authenticationTwoFactorConfirm",
          user_id: user?.number,
        });
        window.scrollTo(0, 0);
        setTOTPLoader(false);
      } else {
        setTOTPLoader(false);
      }
    } else {
      setTOTPLoader(false);
      showMessageWithCloseIconError("Please enter 6 digit OTP!");
    }
  };

  const handleGoBack = () => {
    setTotp(false);
    setSetUpSMS(false);
  };

  return (
    <div>
      <Modal
        open={twoFaModal}
        footer={null}
        onCancel={() => setTwoFaModal(false)}
        maskClosable={false}
        closable={true}
        width={600}
      >
        {(Object.keys(setUpSMS).length > 0 || totp === "showqr") && (
          <ArrowLeftOutlined onClick={handleGoBack} />
        )}

        <div style={{ padding: "20px" }}>
          <div className="kd-2fa-firstdiv">
            {/* {setUpSMS?.phoneNumber ? (
              <img src={twofaicon} alt="2fa" />
            ) : setUpSMS === true ? (
              <img src={twofadone} alt="2fa" />
            ) : (
              <img src={DeviceMobile} alt="2fa" />
            )} */}

            {setUpSMS?.phoneNumber ? (
              <>
                <p className="sb-TwoFa-title m-0">Enter Authentication Code</p>
                <p className="sb-TwoFa-subtitle m-0">
                  We have sent a code to <b>{user?.mobilePhone}</b>
                </p>
              </>
            ) : setUpSMS === true || totp === "completed" ? (
              <>
                <p className="sb-TwoFa-title m-0">2FA Setup Completed</p>
                {user?.secondFactorAuth === "SMS" ? (
                  <p className="sb-TwoFa-subtitle m-0 mb-24">
                    Your account is now more secure. You will receive a
                    verification code via SMS to your number{" "}
                    <b>{user?.mobilePhone}</b> for every login.
                  </p>
                ) : (
                  <p className="sb-TwoFa-subtitle m-0 mb-24">
                    Your account is now more secure. You will need to enter a
                    verification code from your authenticator app for every
                    login.
                  </p>
                )}
              </>
            ) : totp === "showqr" ? (
              <div>
                <div>
                  <p className="auth-head">
                    1.Install the Google Authenticator app on your device
                  </p>
                  <p className="auth-head">
                    2.Scan the QR code with Google Authenticator app.
                  </p>
                </div>

                <div className="sb-TwoFa-center">
                  {qrUrl ? (
                    <QRCode type="svg" value={qrUrl} size={100} />
                  ) : (
                    <p>Loading QR Code...</p>
                  )}
                </div>
                <p className="auth-head">
                  3.Enter the current one time password for your Google
                  Authenticator app.
                </p>

                <div
                  className="authenticate-otp-input-div mb-16"
                  style={{ justifyContent: "center" }}
                >
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    inputStyle={{
                      border: "1px solid #1A202C1A",
                      borderRadius: "12px",
                      width: "40px",
                      height: "40px",
                      fontSize: "18px",
                      fontWeight: "400",
                    }}
                    numInputs={6}
                    renderSeparator={<span></span>}
                    isInputNum={true}
                    inputProps={{
                      type: "number",
                      inputMode: "numeric",
                    }}
                    renderInput={(props) => (
                      <input {...props} type="number" inputMode="numeric" />
                    )}
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="mt-24 mb-16"
                >
                  <ButtonDefault
                    title="Enable"
                    onClick={handleAuthentication}
                    loading={tOTPLoader}
                    block={true}
                  />
                </div>
              </div>
            ) : (
              <>
                <p className="sb-TwoFa-title m-0">Two-Factor Authentication</p>
                <p className="sb-TwoFa-subtitle m-0">
                  Adds an extra layer of security to protect your account from
                  unauthorized access.
                  <br /> Don’t wait—activate it today to safeguard your
                  investments!
                </p>
                <div className="twofa-main">
                  <div
                    className="setting-twofa-modal twofa-margin"
                    style={{ position: "relative" }}
                  >
                    {" "}
                    {tOtpLoader === true && (
                      <div
                        style={{
                          position: "absolute",

                          left: "50%",
                          top: "50%",
                          zIndex: 99,
                          transform: "translate(-37px, -33px)",
                        }}
                      >
                        <ReactLoading
                          type="spin"
                          color="var(--kilde-blue)"
                          height={60}
                          width={60}
                        />
                      </div>
                    )}
                    <div className="twofa-second-div">
                      <img
                        src={Device}
                        alt="authenticateapp"
                        style={{ width: 48, height: 48, marginBottom: "16px" }}
                      />
                      <p className=" auth-head m-0">Authenticator app</p>
                      <p className="twofa-tag">
                        Use the Google Authenticator app on your mobile device
                        to generate a one-time password for login
                      </p>
                    </div>
                    <Button
                      className="twofa-setup-button"
                      onClick={handleSetupTotp}
                      block={true}
                    >
                      Set Up
                    </Button>
                    {/* <div className="twofa-whatisit">
                      <p className="mt-4">what is it?</p>
                    </div> */}
                  </div>
                  <div className="twofa-or">
                    <p className="m-0">Or</p>
                  </div>

                  <div className="setting-twofa-modal twofa-margin-left">
                    <div className="twofa-second-div">
                      <img
                        src={SMS}
                        alt="sms"
                        style={{ width: 48, height: 48, marginBottom: "16px" }}
                      />

                      <p className="auth-head m-0">SMS</p>
                      <p className="twofa-tag ">
                        Receive an SMS message <br />
                        with a login code
                      </p>
                    </div>
                    <Button
                      className="twofa-setup-button"
                      block={true}
                      onClick={handleTwoFaAuth}
                      loading={twoFaLoader}
                    >
                      Set Up
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* {setUpSMS === false && (
            <p className="sb-TwoFa-title mt-24 mb-24">{user?.mobilePhone}</p>
          )} */}
          {/* {(setUpSMS === false || setUpSMS === true) && (
            <p
              className={
                setUpSMS === true
                  ? "sb-twofa-link mt-12 mb-24"
                  : "sb-twofa-link mt-0 mb-24"
              }
            >
              To change your account information, contact us at{" "}
              <span>
                <Link to="mailto:sales@kilde.sg">sales@kilde.sg</Link>
              </span>
            </p>
          )} */}
          {setUpSMS?.phoneNumber !== undefined && (
            <div className="sb-otp-input-div mt-24 mb-24">
              <OtpInput
                value={otp}
                onChange={setOtp}
                inputStyle={inputStyle}
                numInputs={6}
                renderSeparator={<span></span>}
                isInputNum={true}
                inputProps={{ type: "number", inputMode: "numeric" }}
                renderInput={(props) => (
                  <input {...props} type="number" inputMode="numeric" />
                )}
                shouldAutoFocus
              />
            </div>
          )}
          {isTimerRunning && (
            <div className="kd-resend">
              <p className="mt-0 mb-0">
                Resend code in{" "}
                <span id="timer">{isTimerRunning ? `(${time}s)` : ""}</span>{" "}
              </p>
            </div>
          )}
          {!isTimerRunning && setUpSMS?.phoneNumber !== undefined && (
            <p className="sb-twofa-link mb-0" onClick={handleTwoFaAuth}>
              <Link> Resend Code</Link>
            </p>
          )}

          <div className="sb-TwoFa-actions">
            {currentEnv === "DEV" && setUpSMS?.code && (
              <div>
                <p
                  style={{ textAlign: "right", color: "#ddd" }}
                  className="mt-0 mb-5"
                >
                  Authentication Code: {setUpSMS?.code}
                </p>
              </div>
            )}
            {(setUpSMS?.phoneNumber !== undefined ||
              setUpSMS === true ||
              totp === "completed") && (
              <ButtonDefault
                title={
                  setUpSMS?.phoneNumber !== undefined
                    ? "Authenticate"
                    : "Continue"
                }
                block={true}
                onClick={
                  setUpSMS?.phoneNumber !== undefined
                    ? handleEnableSMS
                    : handleContinue
                }
                loading={twoFaLoader}
              />
            )}

            <>
              {user?.secondFactorAuth === null &&
              setUpSMS === false &&
              totp !== "showqr" ? (
                <Button
                  block={true}
                  onClick={skipForNow}
                  className="kd-2fa-dothislater"
                >
                  I’ll do this later
                </Button>
              ) : null}
            </>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TwoFAModal;
