import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Checkbox,
  Input,
  Modal,
  Progress,
  Form,
  Row,
  Col,
  Divider,
  Button,
  notification,
} from "antd";

import InputDefault from "../../Components/InputDefault/InputDefault.jsx";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault.jsx";

import GlobalConfig from "../../Utils/GlobalVariabels.js";
import {
  GoogleRegisterApi,
  RegisterApi,
  generateSignpassCode,
  getSignPassConfig,
  getUser,
} from "../../Apis/UserApi.js";
import ROUTES from "../../Config/Routes.js";
import {
  clearUserSession,
  showMessageWithCloseIcon,
  showMessageWithCloseIconError,
  validatePassword,
} from "../../Utils/Reusables.js";

import PrivacyPdf from "../../Assets/Pdf/Privacy-policy.pdf";
import SingpassLogo from "../../Assets/Images/singpass.svg";
import GoogleIcon from "../../Assets/Images/Icons/google_icon.svg";
// import AppleIcon from "../../Assets/Images/Icons/apple_icon.svg";
import TermsOfUse from "../../Assets/Pdf/Terms of Use.pdf";

import "./style.css";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";

import AuthLayout from "../../Layouts/BlankHeaderLayout/AuthLayout.jsx";
import {
  generateUTMURLAndReferrer,
  getPasswordStrength,
  get_ga_clientid,
} from "../../Utils/Helpers.js";
import { PublicEventApi } from "../../Apis/PublicApi.js";
import PhoneInput from "react-phone-input-2";
import ButtonIcon from "../../Components/ButtonIcon/ButtonIcon.jsx";
import { useGoogleLogin } from "@react-oauth/google";
import GlobalVariabels from "../../Utils/GlobalVariabels.js";
import { identify, trackEvent } from "../../Utils/Analytics.js";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const SignUp = () => {
  const navigate = useNavigate();
  const [hotjarUserId, setHotjarUserId] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    password: "",
    // cPassword: "",
    referralCode: "",
  });
  const [mobileNumberClass, setMobileNumberClass] = useState("");
  const [countryCode, setCountryCode] = useState("sg");
  const strength = getPasswordStrength(formData?.password);
  const [validationErrors, setValidationErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    // cPassword: false,
    referralCode: false,
  });
  const [loader, setLoader] = useState(false);
  const [noPass, setNoPass] = useState(false);
  const [cPassErr, setCPassErr] = useState(false);
  const [checked, setChecked] = useState(false);
  const [noCheckErr, setCheckErr] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [criteria, setCriteria] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
  });

  const validatePasswordCustom = (password) => {
    return {
      length: password.length >= 10,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;

    // Update form data first
    setFormData((prev) => ({ ...prev, password: value }));

    const updatedCriteria = validatePasswordCustom(value);

    // Update validation criteria
    setCriteria(updatedCriteria);

    // Ensure all conditions are met before setting noPass
    setNoPass(!Object.values(updatedCriteria).every(Boolean));
  };

  const hintRef = useRef(null);

  const handleClickOutside = (e) => {
    if (hintRef.current && !hintRef.current.contains(e.target)) {
      setShowHint(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const [googleLoader, setGoogleLoader] = useState(false);
  // const [appleLoader, setAppleLoader] = useState(false);

  const [termsPdf, setTermsPdf] = useState(false);
  const [privacyPdf, setPrivacyPdf] = useState(false);
  const [singPassLoader, setSingPassLoader] = useState(false);
  const [referralCodeValid, setReferralCodeValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [referralCodeValidModal, setReferralCodeValidModal] = useState(false);
  const [referralModal, setReferralModal] = useState(false);
  const [googleRequestBody, setGoogleRequestBody] = useState();
  const [finalGoogleLoader, setFinalGoogleLoader] = useState(false);
  const [isErrorPresent, setIsErrorPresent] = useState(false);

  useEffect(() => {
    const checkErrorPresence = () => {
      const container = document.querySelector(".register-page-checkbox");
      if (container) {
        const errorElement = container.querySelector(
          ".ant-form-item-explain-error"
        );
        setIsErrorPresent(!!errorElement);
      }
    };

    checkErrorPresence();
    const observer = new MutationObserver(checkErrorPresence);
    const targetNode = document.querySelector(".register-page-checkbox");
    if (targetNode) {
      observer.observe(targetNode, { childList: true, subtree: true });
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const getSingPass = async () => {
      await getSignPassConfig()
        .then((respose) => {
          const jsonString = JSON.stringify(respose);
          Cookies.set("singpass_config", jsonString);
        })
        .catch((err) => {
          console.log("singpass_config", err);
        });
    };
    getSingPass();
  }, []);

  const getHotjarUserId = () => {
    // Make sure Hotjar has initialized
    if (window?.hj && typeof window?.hj?.getUserId === "function") {
      const userId = window?.hj?.getUserId();
      return userId ? userId : null; // Check if userId exists
    }
    return null;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const hotjarId = getHotjarUserId();
      if (hotjarId) {
        setHotjarUserId(hotjarId);
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleSingPass = async () => {
    setSingPassLoader(true);
    let singpassGetConfig = Cookies.get("singpass_config");
    if (singpassGetConfig) {
      const singpassConfigration = decodeURIComponent(singpassGetConfig);
      const singpassConfig = JSON.parse(singpassConfigration);
      if (
        singpassConfig?.clientId &&
        singpassConfig?.purposeId &&
        singpassConfig?.redirectUrl &&
        singpassConfig?.scope
      ) {
        setSingPassLoader(true);
        await generateSignpassCode()
          .then((response) => {
            Cookies.set("singpass_code", response?.codeChallenge);
            Cookies.set("sid", response?.sid);
            window.location.href = `${GlobalConfig?.SINGPASS_REDIRECT_URL}?client_id=${singpassConfig?.clientId}&code_challenge=${response?.codeChallenge}&code_challenge_method=${GlobalConfig?.SINGPASS_CODE_CC_METHOD}&purpose_id=${singpassConfig?.purposeId}&redirect_uri=${singpassConfig?.redirectUrl}&response_type=${GlobalConfig?.SINGPASS_RESPONSE_TYPE}&scope=${singpassConfig?.scope}`;
            PublicEventApi("regSingPass");
            setSingPassLoader(false);
          })
          .catch((error) => {
            console.log(error);
            notification.error({
              type: "error",
              message: "Oops! Something happened.",
              description:
                "We're on it! If this continues, please contact support at sales@kilde.sg.",
            });
            setSingPassLoader(false);
          });
      }
    } else {
      setSingPassLoader(false);
      console.log("singpass_config not found");
      notification.error({
        type: "error",
        message: "Oops! Something happened.",
        description:
          "We're on it! If this continues, please contact support at sales@kilde.sg.",
      });
    }
  };

  const HandleCreateUser = async () => {
    clearUserSession();
    setLoader(true);
    setValidationErrors({
      email: true,
      firstName: true,
      lastName: true,
    });

    if (!formData?.password || !validatePassword(formData?.password)) {
      // if (checked === false) {
      //   setCheckErr(true);
      //   setLoader(false);
      // }
      setLoader(false);
      return setNoPass(true);
    }
    // else if (formData?.cPassword !== formData?.password) {
    //   setLoader(false);
    //   return setCPassErr(true);
    // }

    // if (checked === false) {
    //   setLoader(false);
    //   return setCheckErr(true);
    // }

    if (formData?.email) {
      let data;
      if (
        formData?.referralCode === null ||
        formData?.referralCode === undefined ||
        formData?.referralCode === ""
      ) {
        data = {
          firstName: formData?.firstName,
          lastName: formData?.lastName,
          email: formData?.email,
          mobileNumber: formData?.mobileNumber,
          password: formData?.password,
          passwordRepeat: formData?.password,
          acceptTermsOfUse: true,
          acceptPrivacyPolicy: true,
          gaClientId: get_ga_clientid(),
          url: generateUTMURLAndReferrer(hotjarUserId)?.combinedURL,
          httpReferrer:
            generateUTMURLAndReferrer(hotjarUserId)?.originalReferrer,
        };
      } else {
        data = {
          firstName: formData?.firstName,
          lastName: formData?.lastName,
          email: formData?.email,
          mobileNumber: formData?.mobileNumber,
          password: formData?.password,
          // passwordRepeat: formData?.cPassword,
          acceptTermsOfUse: true,
          acceptPrivacyPolicy: true,
          gaClientId: get_ga_clientid(),
          url: generateUTMURLAndReferrer(hotjarUserId)?.combinedURL,
          httpReferrer:
            generateUTMURLAndReferrer(hotjarUserId)?.originalReferrer,
          referralCode: formData?.referralCode,
        };
      }

      if (data && formData?.mobileNumber) {
        RegisterApi(data)
          .then(async (response) => {
            if (response?.token) {
              Cookies.set("auth_inv_token", response?.token);
              setLoader(false);
              navigate(ROUTES.EMAIL_VERIFICATION);
              getUser().then(async (profileResponse) => {
                trackEvent("Investor: registered", {
                  user_id: profileResponse?.number,
                });
                window?.dataLayer?.push({
                  event: "registration-form-success",
                  user_id: profileResponse?.number,
                });
              });
              showMessageWithCloseIcon(
                "We have sent verification email please check!"
              );
              PublicEventApi("personalData");
            } else {
              if (Object.values(response?.fieldErrors).length > 0) {
                setLoader(false);
                if (
                  response?.fieldErrors?.referralCode !== undefined &&
                  response?.fieldErrors?.email !== undefined
                ) {
                  setReferralCodeValid(true);
                  setEmailValid(true);
                }
                if (response?.fieldErrors?.referralCode) {
                  setReferralCodeValid(true);
                } else if (response?.fieldErrors?.email) {
                  setEmailValid(true);
                }
              }
            }
          })
          .catch((error) => {
            console.error("Error during registration:", error);
            setLoader(false);
          });
      } else {
        setLoader(false);
        setMobileNumberClass("sb-phone-empty");
      }
    }
  };

  // const handleOpenReferral = () => {
  //   if (!checked) {
  //     showMessageWithCloseIconError(
  //       "Please accept the Terms of Use & Privacy Policy"
  //     );
  //     return setCheckErr(true);
  //   } else {
  //   setCheckErr(false);
  //   setReferralModal(true);
  //   }
  // };

  const handleBlur = () => {
    const number = formData?.mobileNumber.substring(countryCode?.length).trim();
    if (number !== "") {
      setMobileNumberClass("");
    } else {
      setMobileNumberClass("sb-phone-empty");
    }
  };

  const handlePhoneChange = async (value, country) => {
    setCountryCode(country.dialCode);
    const number = value.substring(country.dialCode?.length).trim();
    const isValueValid = value.trim() === "";
    setFormData((prevAddress) => ({
      ...prevAddress,
      mobileNumber: "+" + value,
    }));

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      mobileNumber: isValueValid,
    }));

    setFormData({
      ...formData,
      mobileNumber: "+" + value,
    });
    if (number !== "") {
      setMobileNumberClass("");
    } else {
      setMobileNumberClass("sb-phone-empty");
    }
  };

  // const handleAppleSignin = async (provider, data) => {
  //   if (!checked) {
  //     return setCheckErr(true);
  //   } else {
  //     setCheckErr(false);
  //   }
  //   setAppleLoader(true);
  // };

  const handleOpenTermsPdf = () => {
    setTermsPdf(true);
  };

  const handleCloseTermsPdf = () => {
    setTermsPdf(false);
  };

  const handleGoogle = async (data) => {
    if (data) {
      setReferralModal(true);
    }
    setGoogleLoader(false);
    let requestBody = {
      gaClientId: get_ga_clientid(),
      acceptPrivacyPolicy: true,
      acceptTermsOfUse: true,
      email: data?.email,
      firstName: data?.given_name,
      lastName: data?.family_name,
      url: generateUTMURLAndReferrer(hotjarUserId)?.combinedURL,
      httpReferrer: generateUTMURLAndReferrer(hotjarUserId)?.originalReferrer,
    };
    setGoogleRequestBody(requestBody);
  };

  const finalGoogleApi = () => {
    setFinalGoogleLoader(true);
    let requestBody;
    if (
      formData?.referralCode === null ||
      formData?.referralCode === undefined ||
      formData?.referralCode === ""
    ) {
      requestBody = googleRequestBody;
    } else {
      requestBody = {
        ...googleRequestBody,
        referralCode: formData?.referralCode,
      };
    }
    GoogleRegisterApi(requestBody)
      .then(async (response) => {
        setFinalGoogleLoader(false);
        setFormData({
          ...formData,
          referralCode: "",
        });
        if (response?.token) {
          identify({
            email: googleRequestBody?.email,
          });
          Cookies.set("auth_inv_token", response?.token);
          getUser().then(async (profileResponse) => {
            trackEvent("Investor: registered", {
              user_id: profileResponse?.number,
            });
            Cookies.set("user", JSON.stringify(profileResponse));
            window?.dataLayer?.push({
              event: "google-success",
              user_id: profileResponse?.number,
            });
          });
          setGoogleRequestBody("");
          setGoogleLoader(false);
          setLoader(false);
          navigate(ROUTES.VERIFICATION);
        } else {
          if (Object.values(response?.fieldErrors).length > 0) {
            setFormData({
              ...formData,
              referralCode: "",
            });
            setGoogleLoader(false);
            setLoader(false);
            setReferralModal(false);
          }
        }
      })
      .catch((error) => {
        console.error("Error during registration:", error);
        setGoogleLoader(false);
        setLoader(false);
      });
  };

  const login = useGoogleLogin({
    onSuccess: async (credentialResponse) => {
      const { access_token } = credentialResponse;

      try {
        const userInfoResponse = await fetch(
          GlobalVariabels.GOOGLE_USERINFO_URI,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (userInfoResponse.ok) {
          const userInfo = await userInfoResponse.json();
          handleGoogle(userInfo);
        } else {
          console.error(
            "Failed to fetch user information:",
            userInfoResponse.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching user information:", error);
      }
    },
  });

  return (
    <>
      <AuthLayout>
        {singPassLoader && (
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              zIndex: 99,
            }}
          >
            <ReactLoading
              type="spin"
              color="var(--kilde-blue)"
              height={60}
              width={60}
            />
          </div>
        )}
        <div className="sb-onboarding-register-form-container">
          <div className="register-padd-container mt-50">
            <p className="kl-pi-title mb-10 mt-0">Welcome!</p>
            <p className="kl-subtitle m-0">Let’s create an account for you.</p>
          </div>

          <div className="register-btn-div">
            <p className="mt-5 mb-16 sb-pi-singpass-subtitle">
              Kilde products are intended for accredited, professional and
              institutional investors.
              {/* <span
                className="cursor-pointer"
                onMouseEnter={(e) => {
                  e.target.style.color = "var(--kilde-blue)";
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = "rgba(26, 32, 44, 0.4)";
                }}
                onClick={() =>
                  window.open(
                    "https://www.kilde.sg/investing/what-does-it-mean-to-be-an-accredited-investor-in-singapore",
                    "_blank"
                  )
                }
              >
                Learn more
              </span> */}
            </p>

            <div className="register-padd-container">
              <Button
                className="btn-singpass"
                loading={singPassLoader}
                onClick={handleSingPass}
                id="btn-singpass-signup"
              >
                <p className="mb-5 singpass-text">Sign up with </p>
                <img src={SingpassLogo} alt="singpass_logo" />
              </Button>
            </div>

            <p className="mt-5 mb-16 singpass-sub-text">
              We suggest Singaporeans use Singpass.{" "}
              <span
                className="cursor-pointer"
                style={{ color: "var(--kilde-blue)" }}
                onClick={() =>
                  window.open(
                    "https://www.singpass.gov.sg/main/individuals/",
                    "_blank"
                  )
                }
              >
                Find out more
              </span>
            </p>

            {/* <div className="sb-flex-column-item-center mt-20 w-100">
      <LoginSocialApple
        client_id={CLIENT_ID.APPLE_CLIENTID}
        scope={"name email"}
        redirect_uri={
          "https://plenty-planets-beam-42-118-51-2.loca.lt/account/login"
        }
        onResolve={({ provider, data }) => {
          handleAppleSignin(provider, data);
        }}
        onReject={(err) => {
          console.log(err);
        }}
        className="w-100"
      >
        <ButtonIcon
          title="Sign up with Apple"
          icon={<img src={AppleIcon} alt="AppleIcon" />}
          loading={appleLoader}
          block={true}
        />
      </LoginSocialApple>
    </div> */}
            <div className="register-padd-container">
              <div className="sb-flex-column-item-center mt-20 w-100 login-div">
                {/* {checked === false ? (
                  <ButtonIcon
                    title="Sign up with Google"
                    icon={<img src={GoogleIcon} alt="google_icon" />}
                    loading={googleLoader}
                    className="google-btn"
                    onClick={handleOpenReferral}
                    id="btn-google-signup"
                  />
                ) : ( */}
                <ButtonIcon
                  title="Sign up with Google"
                  icon={<img src={GoogleIcon} alt="google_icon" />}
                  loading={googleLoader}
                  className="google-btn"
                  onClick={() => login()}
                  id="btn-google-signup"
                />
                {/* )} */}
              </div>
            </div>
          </div>
          <div
            className="sb-login-actions register-padd-container"
            style={{ gap: 4 }}
          >
            <Divider plain>
              <p style={{ color: "#ababab" }}> Or with Email </p>
            </Divider>
          </div>
          <div className="register-padd-container mb-50">
            <Form
              onFinish={HandleCreateUser}
              name="wrap"
              labelCol={{ flex: "110px" }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ flex: 1 }}
              colon={false}
            >
              <div className="sb-login-form">
                <Row gutter={16}>
                  <Col className="gutter-row " md={12} sm={12} xs={24}>
                    <Form.Item
                      name="firstName"
                      rules={[
                        { required: true, message: "First name is required" },
                      ]}
                      className="mb-16"
                    >
                      <InputDefault
                        name="firstName"
                        placeholder="First Name"
                        onChange={({ target }) =>
                          setFormData({ ...formData, firstName: target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row " md={12} sm={12} xs={24}>
                    <Form.Item
                      name="lastName"
                      rules={[
                        { required: true, message: "Last name is required" },
                      ]}
                      className="mb-16"
                    >
                      <InputDefault
                        name="lastName"
                        placeholder="Last Name"
                        onChange={({ target }) =>
                          setFormData({ ...formData, lastName: target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row " md={24} sm={24} xs={24}>
                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, message: "Email is required" },
                        {
                          type: "email",
                          message: "Please enter a valid email",
                        },
                      ]}
                      className="mb-16"
                    >
                      <InputDefault
                        name="email"
                        placeholder="Email"
                        onChange={({ target }) => {
                          setFormData({ ...formData, email: target.value });
                          setEmailValid(false);
                        }}
                        id="email"
                        className={emailValid === true ? "referral-error" : ""}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    className="gutter-row  sb-text-align-start"
                    md={24}
                    sm={24}
                    xs={24}
                  >
                    <Form.Item
                      name="mobileNumber"
                      rules={[
                        {
                          required: true,
                          message: "Mobile number is required",
                        },
                      ]}
                      className="mb-16"
                    >
                      <PhoneInput
                        name="mobileNumber"
                        className={`sb-phone-field ${mobileNumberClass}`}
                        country={countryCode}
                        value={formData.mobileNumber}
                        onChange={handlePhoneChange}
                        enableSearch
                        onBlur={handleBlur}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" md={24} sm={24} xs={24}>
                    <div style={{ marginBottom: 12 }} ref={hintRef}>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            validator: (_, value) =>
                              value && value.length >= 10
                                ? Promise.resolve()
                                : Promise.reject("Password is required"),
                          },
                        ]}
                        style={{ marginBottom: "0px" }}
                      >
                        <Input.Password
                          name="password"
                          placeholder="Password"
                          style={{ height: 40, borderRadius: "12px" }}
                          onFocus={() => setShowHint(true)}
                          onChange={handlePasswordChange}
                          status={noPass ? "error" : ""}
                          id="password"
                        />
                      </Form.Item>

                      {showHint && (
                        <div
                          className={`password-hint ${showHint ? "show" : ""}`}
                          style={{
                            padding: "10px",
                            background: "#f9f9f9",
                            borderRadius: "8px",
                            marginTop: "8px",
                            fontSize: "12px",
                            display: showHint ? "block" : "none",
                          }}
                        >
                          {/* <p style={{ marginBottom: "4px", fontWeight: 500 }}>
                            Password must include:
                          </p> */}
                          <ul
                            style={{
                              paddingLeft: "16px",
                              listStyle: "none",
                              textAlign: "left",
                              fontWeight: 500,
                            }}
                          >
                            {[
                              { key: "length", text: "At least 10 characters" },
                              {
                                key: "lowercase",
                                text: "At least one lowercase letter",
                              },
                              {
                                key: "uppercase",
                                text: "At least one uppercase letter",
                              },
                              {
                                key: "number",
                                text: "At least one numeric character",
                              },
                              {
                                key: "special",
                                text: "At least one special character",
                              },
                            ].map(({ key, text }) => (
                              <li
                                key={key}
                                style={{
                                  color: criteria[key] ? "green" : "#939393",
                                }}
                              >
                                {criteria[key] ? (
                                  <CheckCircleOutlined
                                    style={{ color: "green" }}
                                  />
                                ) : (
                                  <CloseCircleOutlined
                                    style={{ color: "#939393" }}
                                  />
                                )}{" "}
                                {text}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </Col>
                  {/* <Col className="gutter-row " md={24} sm={24} xs={24}>
                    <Form.Item
                      name="cPassword"
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                          message: "Confirm password is required",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject("Passwords don't match!");
                          },
                        }),
                      ]}
                      className="mb-16"
                    >
                      <Input.Password
                        name="cPassword"
                        placeholder="Confirm Password"
                        style={{ height: 40, borderRadius: "12px" }}
                        onChange={({ target }) => {
                          setCPassErr(false);
                          setFormData({
                            ...formData,
                            cPassword: target.value,
                          });
                          if (target.value !== formData?.password) {
                            setCPassErr(true);
                            setValidationErrors({
                              ...validationErrors,
                              cPassword: false,
                            });
                          }
                        }}
                        status={cPassErr ? "error" : ""}
                        id="cPassword"
                      />
                    </Form.Item>
                  </Col> */}
                  <Col
                    className="gutter-row mb-16"
                    md={24}
                    sm={24}
                    xs={24}
                    style={{ marginTop: 4 }}
                  >
                    <div
                      className={
                        referralCodeValid === true ? "referral-error" : ""
                      }
                    >
                      <InputDefault
                        name="ReferralCode"
                        placeholder="Referral code"
                        type="text"
                        validationState={setValidationErrors}
                        focusing={validationErrors?.referralCode}
                        onChange={({ target }) => {
                          setFormData({
                            ...formData,
                            referralCode: target.value,
                          });
                          setReferralCodeValid(false);
                        }}
                        required={false}
                      />
                    </div>
                  </Col>
                  <Col className="gutter-row mb-16" md={24} sm={24} xs={24}>
                    <div
                      style={{ textAlign: "start" }}
                      className="register-page-checkbox"
                    >
                      By continuing you agree to Kilde’s{" "}
                      <span
                        className="cursor-pointer termsofuse"
                        style={{ color: "var(--kilde-blue)" }}
                        onClick={() => setPrivacyPdf(true)}
                      >
                        Terms of Use
                      </span>{" "}
                      and{" "}
                      <span
                        className="cursor-pointer termsofuse"
                        style={{ color: "var(--kilde-blue)" }}
                        onClick={handleOpenTermsPdf}
                      >
                        {" "}
                        Privacy Policy
                      </span>
                      {/* <Form.Item
                        className="mb-0"
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Please accept the Terms of Use & Privacy Policy"
                                    )
                                  ),
                          },
                        ]}
                      >
                        <Checkbox
                          className="checkbox-kilde"
                          checked={checked}
                          onChange={(e) => {
                            setChecked(e.target.checked);
                            setCheckErr(!e.target.checked);
                          }}
                        >
                          I accept{" "}
                          <span
                            className="cursor-pointer"
                            style={{ color: "var(--kilde-blue)" }}
                            onClick={() => setPrivacyPdf(true)}
                          >
                            Terms of use
                          </span>{" "}
                          and
                          <span
                            className="cursor-pointer"
                            style={{ color: "var(--kilde-blue)" }}
                            onClick={handleOpenTermsPdf}
                          >
                            {" "}
                            Privacy Policy
                          </span>
                        </Checkbox>
                      </Form.Item>

                      {noCheckErr && !isErrorPresent ? (
                        <label
                          className="error-msg mt-0"
                          style={{
                            display: "block",
                            marginBottom: 12,
                            marginLeft: 10,
                            fontSize: "12px",
                          }}
                        >
                          Please accept the Terms of Use & Privacy Policy
                        </label>
                      ) : null} */}
                    </div>
                  </Col>

                  <Col
                    className="gutter-row mb-10 mt-10"
                    md={24}
                    sm={24}
                    xs={24}
                  >
                    <Form.Item>
                      <ButtonDefault
                        title="Create account"
                        loading={loader}
                        style={{ width: "100%" }}
                        block={true}
                        id="btn-create-account"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Form>
            <div className="sb-forgot-new-here">
              <div className="kl-subtitle mt-0 mb-50">
                Already have an account?
                <Link className="fp-link" to={ROUTES.LOGIN}>
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Modal
          centered
          open={referralModal}
          onCancel={() => {
            setReferralModal(false);
          }}
          width={460}
          footer={null}
          maskClosable={false}
        >
          <p>
            Got a referral code from a friend? Use it here to unlock your
            reward! (T&C apply).
          </p>
          <div
            className={referralCodeValidModal === true ? "referral-error" : ""}
          >
            <InputDefault
              name="ReferralCode"
              placeholder="Referral code"
              type="text"
              validationState={setValidationErrors}
              focusing={validationErrors?.referralCode}
              onChange={({ target }) => {
                setFormData({ ...formData, referralCode: target.value });
                setReferralCodeValidModal(false);
              }}
              required={true}
              errorMsg={"Please enter referral code"}
            />
          </div>

          <div className="sb-text-align mt-20">
            <ButtonDefault
              title="Skip and Continue"
              loading={finalGoogleLoader}
              onClick={() => {
                finalGoogleApi();
                clearUserSession();
              }}
            />
          </div>
        </Modal>

        <Modal
          className="sb-pdf-modal"
          centered
          open={termsPdf}
          onCancel={handleCloseTermsPdf}
          width={1000}
          footer={null}
        >
          <iframe
            className="mt-20"
            src={`${PrivacyPdf}#toolbar=0`}
            width="100%"
            height="500px"
            title="PDF Viewer"
          />
        </Modal>

        <Modal
          className="sb-pdf-modal"
          centered
          open={privacyPdf}
          onCancel={() => setPrivacyPdf(false)}
          width={1000}
          footer={null}
        >
          <iframe
            className="mt-20"
            src={TermsOfUse}
            width="100%"
            height="500px"
            title="PDF Viewer"
          />
        </Modal>
      </AuthLayout>
    </>
  );
};

export default SignUp;
