/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import ButtonDefault from "../../../Components/ButtonDefault/ButtonDefault";
import InputDefault from "../../../Components/InputDefault/InputDefault";
import {
  CapitalCallList,
  CapitalCallRequest,
} from "../../../Apis/DashboardApi";
import { ErrorResponse } from "../../../Utils/ErrorResponse";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCapitalRequests } from "../../../Redux/Action/Investor";
import Borrower_warning from "../../../Assets/Images/Icons/Dashboard/borrower_warning.svg";
import {
  formatCurrency,
  showMessageWithCloseIcon,
  showMessageWithCloseIconError,
} from "../../../Utils/Reusables";
import { LoadingOutlined } from "@ant-design/icons";
import { britishFormatDate } from "../../../Utils/Helpers";

const StartCapitalCallRequest = ({ TrancheRes, setCapitalRequestLoading }) => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [capitalRequestLoader, setCapitalRequestLoader] = useState(false);
  const [loader, setLoader] = useState(false);

  const [investAmount, setInvestAmount] = useState({
    amount: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    amount: false,
  });
  const [backendError, setBackendError] = useState(null);
  const [redemptionModal, setRedemptionModal] = useState(null);

  useEffect(() => {
    const amount = investAmount?.amount;

    let errors = { ...validationErrors };
    if (investAmount?.amount !== "") {
      if (amount < TrancheRes?.tranche?.nominalValue) {
        errors.amount = true;
      } else if (amount > TrancheRes?.investment?.principalSettled) {
        errors.amount = true;
      } else {
        errors.amount = false;
      }
    }

    setValidationErrors(errors);
  }, [investAmount?.amount]);

  const handleChange = (e) => {
    setBackendError(null);
    const { name, value } = e.target;
    const amount = value ? parseFloat(value) : "";

    let errors = { ...validationErrors };

    if (amount === "" || isNaN(amount)) {
      errors.amount = true;
    } else if (amount < TrancheRes?.tranche?.nominalValue) {
      errors.amount = true;
    } else if (amount > TrancheRes?.investment?.principalSettled) {
      errors.amount = true;
    } else {
      errors.amount = false;
    }

    setInvestAmount({ ...investAmount, [e.target.name]: e.target.value });
    setValidationErrors(errors);
  };

  const handleOpenModal = () => {
    setLoader(true);
    if (investAmount?.amount === "") {
      setValidationErrors({
        amount: true,
      });
      showMessageWithCloseIconError("Please enter an amount.");
      setLoader(false);
    } else if (investAmount?.amount < TrancheRes?.tranche?.nominalValue) {
      setValidationErrors({
        amount: true,
      });
      showMessageWithCloseIconError(
        "Please enter amount more than nominal value"
      );
      setLoader(false);
    } else {
      setLoader(false);
      setRedemptionModal(true);
    }
  };

  const handleCapitalRequest = () => {
    setBackendError(null);
    setCapitalRequestLoader(true);
    if (investAmount?.amount === "") {
      setValidationErrors({
        amount: true,
      });
      showMessageWithCloseIconError("Please enter an amount.");
      setCapitalRequestLoader(false);
    } else if (investAmount?.amount < TrancheRes?.tranche?.nominalValue) {
      setValidationErrors({
        amount: true,
      });
      showMessageWithCloseIconError(
        "Please enter amount more than nominal value"
      );
      setCapitalRequestLoader(false);
    } else {
      setValidationErrors({
        amount: false,
      });
      const requestBody = {
        trancheUuid: TrancheRes?.tranche?.uuid,
        amount: investAmount?.amount,
      };
      CapitalCallRequest(requestBody)
        .then(async (commitInvestmentData) => {
          if (commitInvestmentData === "") {
            showMessageWithCloseIcon("Request successfully added.");
            setInvestAmount({
              amount: "",
            });
            setCapitalRequestLoader(false);
            getCapitalCallRequestList();
          } else {
            if (
              commitInvestmentData?.fieldErrors?.amount ===
              "CAPITAL_CALL_EXCEEDS_TOTAL_INVESTMENTS"
            ) {
              setValidationErrors({
                amount: true,
              });
              setBackendError(commitInvestmentData?.fieldErrors?.amount);
              setCapitalRequestLoader(false);
            } else if (
              commitInvestmentData?.fieldErrors?.amount ===
              "NO_SPLIT_IN_DEBENTURE_AMOUNT"
            ) {
              setValidationErrors({
                amount: true,
              });
              setBackendError(commitInvestmentData?.fieldErrors?.amount);
              setCapitalRequestLoader(false);
            } else {
              setCapitalRequestLoader(false);
            }
          }
        })
        .catch((error) => {
          ErrorResponse(error?.code);
          setCapitalRequestLoader(false);
        });
    }
  };

  const getCapitalCallRequestList = () => {
    setCapitalRequestLoading(true);
    const requestBody = {
      trancheUuid: slug,
    };
    CapitalCallList(requestBody)
      .then(async (capitaRequestlist) => {
        if (Object.keys(capitaRequestlist)?.length > 0) {
          await setCapitalRequests(capitaRequestlist, dispatch);
          setCapitalRequestLoading(false);
        } else {
          setCapitalRequestLoading(false);
        }
      })
      .catch((error) => {
        ErrorResponse(error?.code);
        setCapitalRequestLoading(false);
      });
  };

  return (
    <Col className="gutter-row infomation-div" lg={24} md={24} sm={24} xs={24}>
      <p className="m-0 tranch-head">Early Redemption Request (Capital Call)</p>

      <div
        className="mt-15 start-invest-div"
        style={{ display: "flex", alignItems: "start" }}
      >
        <div
          style={{ flex: 1 }}
          className={validationErrors.amount === true ? "error-border" : ""}
        >
          <div style={{ position: "relative" }}>
            <InputDefault
              value={investAmount?.amount}
              placeholder="Enter amount"
              type="text"
              name="amount"
              onChange={handleChange}
              required={true}
              errorMsg={"Amount is Required"}
              style={{ backgroundColor: "#ffffff" }}
            />
            {validationErrors.amount === true && (
              <div className="borrower-warning-logo">
                <img src={Borrower_warning} alt="Borrower_warning" />
              </div>
            )}
          </div>
          {validationErrors.amount === true && investAmount.amount === "" ? (
            <span style={{ color: "red", fontSize: "12px" }}>
              Please enter an amount
            </span>
          ) : validationErrors.amount === true &&
            investAmount.amount !== "" &&
            investAmount?.amount < TrancheRes?.tranche?.nominalValue ? (
            <span style={{ color: "red", fontSize: "12px" }}>
              Please enter amount more than minimum amount
            </span>
          ) : validationErrors.amount === true &&
            investAmount.amount !== "" &&
            investAmount?.amount > TrancheRes?.investment?.principalSettled ? (
            <span style={{ color: "red", fontSize: "12px" }}>
              The maximum redemption for this investment is{" "}
              {formatCurrency(
                TrancheRes?.tranche?.currencyCode === "USD"
                  ? "$"
                  : TrancheRes?.tranche?.currencyCode === "SGD"
                  ? "S$"
                  : "€",
                TrancheRes?.investment?.principalSettled
              )}
            </span>
          ) : validationErrors.amount === true &&
            investAmount.amount !== "" &&
            backendError === "NO_SPLIT_IN_DEBENTURE_AMOUNT" ? (
            <span style={{ color: "red", fontSize: "12px" }}>
              Amount cannot be split in debenture amounts
            </span>
          ) : null}
          <p className="capital-info-note mt-4 mb-0">
            Min. amount{" "}
            <span
              onClick={() =>
                setInvestAmount({ amount: TrancheRes?.tranche?.nominalValue })
              }
              className="cursor-pointer"
              style={{ fontWeight: "bold" }}
            >
              {formatCurrency(
                TrancheRes?.tranche?.currencyCode === "USD"
                  ? "$"
                  : TrancheRes?.tranche?.currencyCode === "SGD"
                  ? "S$"
                  : "€",
                TrancheRes?.tranche?.nominalValue
              )}
            </span>
            , max{" "}
            <span
              onClick={() =>
                setInvestAmount({
                  amount: TrancheRes?.investment?.principalSettled,
                })
              }
              className="cursor-pointer"
              style={{ fontWeight: "bold" }}
            >
              {formatCurrency(
                TrancheRes?.tranche?.currencyCode === "USD"
                  ? "$"
                  : TrancheRes?.tranche?.currencyCode === "SGD"
                  ? "S$"
                  : "€",
                TrancheRes?.investment?.principalSettled
              )}
            </span>
          </p>
        </div>
        <div>
          <ButtonDefault
            title="Request"
            className="invest-btn"
            loading={loader}
            style={{ width: "100%" }}
            onClick={handleOpenModal}
            disabled={
              investAmount?.amount !== "" &&
              (investAmount?.amount >
                TrancheRes?.investment?.principalSettled ||
                investAmount?.amount < TrancheRes?.tranche?.nominalValue)
            }
          />
        </div>
      </div>
      <Modal
        centered
        open={redemptionModal}
        onCancel={() => {
          setRedemptionModal(false);
        }}
        width={460}
        footer={null}
        maskClosable={false}
        className="invest-modal"
      >
        <p className="m-0 tranch-head mb-24">Early Redemption Request</p>

        <div
          className="start-invest-div"
          style={{ display: "flex", alignItems: "start" }}
        >
          <div
            style={{ flex: 1 }}
            className={validationErrors.amount === true ? "error-border" : ""}
          >
            <div style={{ position: "relative" }}>
              <InputDefault
                value={investAmount?.amount}
                placeholder="Enter amount to withdraw"
                type="text"
                name="amount"
                onChange={handleChange}
                required={true}
                errorMsg={"Amount is Required"}
                style={{ backgroundColor: "#ffffff" }}
              />
              {validationErrors.amount === true && (
                <div className="borrower-warning-logo">
                  <img src={Borrower_warning} alt="Borrower_warning" />
                </div>
              )}
            </div>
            {validationErrors.amount === true && investAmount.amount === "" ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                Please enter an amount
              </span>
            ) : validationErrors.amount === true &&
              investAmount.amount !== "" &&
              investAmount?.amount < TrancheRes?.tranche?.nominalValue ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                Please enter amount more than minimum amount
              </span>
            ) : validationErrors.amount === true &&
              investAmount.amount !== "" &&
              investAmount?.amount >
                TrancheRes?.investment?.principalSettled ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                The maximum redemption for this investment is{" "}
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  TrancheRes?.investment?.principalSettled
                )}
              </span>
            ) : validationErrors.amount === true &&
              investAmount.amount !== "" &&
              backendError === "NO_SPLIT_IN_DEBENTURE_AMOUNT" ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                Amount cannot be split in debenture amounts
              </span>
            ) : null}
            <p className="capital-info-note mt-4 mb-0">
              Min. amount{" "}
              <span
                onClick={() =>
                  setInvestAmount({ amount: TrancheRes?.tranche?.nominalValue })
                }
                className="cursor-pointer"
                style={{ fontWeight: "bold" }}
              >
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  TrancheRes?.tranche?.nominalValue
                )}
              </span>
              , max{" "}
              <span
                onClick={() =>
                  setInvestAmount({
                    amount: TrancheRes?.investment?.principalSettled,
                  })
                }
                className="cursor-pointer"
                style={{ fontWeight: "bold" }}
              >
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  TrancheRes?.investment?.principalSettled
                )}
              </span>
            </p>
          </div>
        </div>
        <div>
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 50, color: "var(--kilde-blue)" }}
              />
            }
            spinning={capitalRequestLoader}
          >
            <div className="mt-24">
              <div className="sb-flex-justify-between mb-16">
                <div>
                  <p className="m-0">Redemption principal</p>
                </div>
                <div>
                  <p className="m-0">
                    {formatCurrency(
                      TrancheRes?.tranche?.currencyCode === "USD"
                        ? "$"
                        : TrancheRes?.tranche?.currencyCode === "SGD"
                        ? "S$"
                        : "€",
                      investAmount?.amount
                    )}
                  </p>
                </div>
              </div>

              <div className="sb-flex-justify-between mb-16">
                <div>
                  <p className="m-0">Fees</p>
                </div>
                <div>
                  <p className="m-0">
                    {formatCurrency(
                      TrancheRes?.tranche?.currencyCode === "USD"
                        ? "$"
                        : TrancheRes?.tranche?.currencyCode === "SGD"
                        ? "S$"
                        : "€",
                      0
                    )}
                  </p>
                </div>
              </div>
              <div className="sb-flex-justify-between mb-16">
                <div>
                  <p className="m-0">Redemption date</p>
                </div>
                <div>
                  <p className="m-0">
                    {britishFormatDate(
                      TrancheRes?.investment?.nextCapitalCallDate
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div className="sb-text-align mt-24">
              <ButtonDefault
                style={{ width: "100%" }}
                className="commit-btn"
                title="Request"
                onClick={handleCapitalRequest}
                loading={capitalRequestLoader}
                disabled={
                  investAmount?.amount >
                    TrancheRes?.investment?.principalSettled ||
                  investAmount?.amount < TrancheRes?.tranche?.nominalValue ||
                  investAmount?.amount === ""
                }
              />
            </div>
          </Spin>
        </div>
      </Modal>
    </Col>
  );
};

export default StartCapitalCallRequest;
