/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Checkbox,
  Input,
  Modal,
  Progress,
  Row,
  Col,
  DatePicker,
  notification,
} from "antd";

import InputDefault from "../../Components/InputDefault/InputDefault.jsx";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault.jsx";

import {
  RegisterSingpassApi,
  eventsApi,
  getSingPassUserData,
  getUser,
} from "../../Apis/UserApi.js";
import ROUTES from "../../Config/Routes.js";
import {
  formatAddress,
  showMessageWithCloseIcon,
  validatePassword,
} from "../../Utils/Reusables.js";

import PrivacyPdf from "../../Assets/Pdf/Privacy-policy.pdf";
import SelectDefault from "../../Components/SelectDefault/SelectDefault.jsx";
import dayjs from "dayjs";
import {
  generateUTMURLAndReferrer,
  getCountries,
  getPasswordStrength,
  get_ga_clientid,
} from "../../Utils/Helpers.js";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import "../RegisterPage/style.css";
import Cookies from "js-cookie";
import AuthLayout from "../../Layouts/BlankHeaderLayout/AuthLayout.jsx";
import SingpassLoader from "./SingpassLoader.jsx";
import { trackEvent } from "../../Utils/Analytics.js";
import TermsOfUse from "../../Assets/Pdf/Terms of Use.pdf";
import moment from "moment";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import "./style.css";

const SingpassRegisterPage = () => {
  const navigate = useNavigate();
  const [singPassUser, setSingPassUser] = useState();
  const singPassCode = sessionStorage.getItem("sCode");
  const [countryCode, setCountryCode] = useState("sg");
  const [mobileNumberClass, setMobileNumberClass] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [criteria, setCriteria] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
  });

  const validatePasswordCustom = (password) => {
    return {
      length: password.length >= 10,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;

    // Update form data first
    setFormData((prev) => ({ ...prev, password: value }));

    const updatedCriteria = validatePasswordCustom(value);

    // Update validation criteria
    setCriteria(updatedCriteria);

    // Ensure all conditions are met before setting noPass
    setNoPass(!Object.values(updatedCriteria).every(Boolean));
  };

  const hintRef = useRef(null);

  const handleClickOutside = (e) => {
    if (hintRef.current && !hintRef.current.contains(e.target)) {
      setShowHint(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const countryList = getCountries();

  useEffect(() => {
    const requestBody = {
      authCode: singPassCode,
      sid: Cookies.get("sid"),
    };
    if (singPassCode && Cookies.get("sid")) {
      getSingPassUserData(requestBody)
        .then((userData) => {
          if (userData?.status === true) {
            setSingPassUser(userData?.data?.data_json);
            sessionStorage.setItem("singPassId", userData?.data?.id);
          } else {
            Cookies.remove("singpass_config");
            Cookies.remove("sid");
            navigate(ROUTES.REGISTER);
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    } else {
      console.log("sid not found");
      navigate(ROUTES.REGISTER);
      notification.error({
        message: "Error",
        description:
          "Something went wrong, Please check your input and try again.",
      });
    }
  }, [singPassCode]);

  const [formData, setFormData] = useState({
    firstName: "",
    marriedName: "",
    sex: "Male",
    dob: null,
    nricFin: "",
    countryOfBirth: "",
    nationality: "",
    residentialStatus: "",
    passType: "",
    passExDate: "",
    registeredAddress: "",
    assemleIncome: "",
    phoneNumber: "",
    email: "",
    password: "",
    cPassword: "",
    referralCode: "",
  });

  useEffect(() => {
    if (singPassUser !== undefined) {
      setCountryCode(singPassUser?.mobileno?.areacode?.value);
      setFormData({
        ...formData,
        firstName: singPassUser?.name?.value,
        marriedName: singPassUser?.marriedname?.value,
        sex: singPassUser?.sex?.desc,
        dob: singPassUser?.dob?.value
          ? new Date(singPassUser?.dob?.value)
          : null,
        nricFin: singPassUser?.uinfin?.value,
        countryOfBirth: singPassUser?.birthcountry?.desc,
        nationality: singPassUser?.nationality?.desc,
        residentialStatus: singPassUser?.residentialstatus?.desc,
        passType: singPassUser?.passtype?.desc,
        passExDate: singPassUser?.passexpirydate?.value
          ? new Date(singPassUser?.passexpirydate?.value)
          : null,
        registeredAddress: formatAddress(singPassUser?.regadd),
        assemleIncome: singPassUser?.["noa-basic"]?.amount?.value,
        phoneNumber:
          "+" +
          singPassUser?.mobileno?.areacode?.value +
          singPassUser?.mobileno?.nbr?.value,
        email: singPassUser?.email?.value,
      });
    }
  }, [singPassUser]);
  const strength = getPasswordStrength(formData?.password);

  const [validationErrors, setValidationErrors] = useState({
    firstName: false,
    marriedName: false,
    sex: false,
    dob: false,
    nricFin: false,
    countryOfBirth: false,
    nationality: false,
    passExDate: false,
    registeredAddress: false,
    assemleIncome: false,
    phoneNumber: false,
    email: false,
    password: false,
    cPassword: false,
    referralCode: false,
  });
  const [loader, setLoader] = useState(false);
  const [noPass, setNoPass] = useState(false);
  const [cPassErr, setCPassErr] = useState(false);
  const [checked, setChecked] = useState(false);
  const [noCheckErr, setCheckErr] = useState(false);
  const [hotjarUserId, setHotjarUserId] = useState("");

  const [termsPdf, setTermsPdf] = useState(false);
  const [privacyPdf, setPrivacyPdf] = useState(false);
  const [referralCodeValid, setReferralCodeValid] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const handlePhoneChange = async (value, country) => {
    setCountryCode(country.dialCode);
    const number = value.substring(country.dialCode.length).trim();

    const isValueValid = value.trim() === "";
    setFormData((prevAddress) => ({
      ...prevAddress,
      phoneNumber: "+" + value,
    }));

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      phoneNumber: isValueValid,
    }));

    if (number !== "") {
      setMobileNumberClass("");
    } else {
      setMobileNumberClass("sb-phone-empty");
    }
  };

  const handleBlur = () => {
    const number = formData.phoneNumber.substring(countryCode.length).trim();
    if (number !== "") {
      setMobileNumberClass("");
    } else {
      setMobileNumberClass("sb-phone-empty");
    }
  };

  const getHotjarUserId = () => {
    // Make sure Hotjar has initialized
    if (window?.hj && typeof window?.hj?.getUserId === "function") {
      const userId = window?.hj?.getUserId();
      return userId ? userId : null; // Check if userId exists
    }
    return null;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const hotjarId = getHotjarUserId();
      if (hotjarId) {
        setHotjarUserId(hotjarId);
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (validationErrors?.phoneNumber === true) {
      setMobileNumberClass("sb-phone-empty");
    }
    if (formData?.phoneNumber) {
      setMobileNumberClass("");
    }
  }, [validationErrors?.phoneNumber]);

  const HandleCreateUser = async () => {
    setLoader(true);
    setValidationErrors({
      email: true,
      password: true,
      cPassword: true,
    });

    // if (checked === false) {
    //   setCheckErr(true);
    //   setLoader(false);
    // }

    if (!formData?.password || !validatePassword(formData?.password)) {
      setLoader(false);
      return setNoPass(true);
    } else if (formData?.password !== formData?.password) {
      setLoader(false);
      return setCPassErr(true);
    }

    if (formData?.email) {
      let data;
      if (
        formData?.referralCode === null ||
        formData?.referralCode === undefined ||
        formData?.referralCode === ""
      ) {
        data = {
          myInfoReference: Cookies.get("sid"),
          email: formData?.email,
          mobilePhone: formData?.phoneNumber,
          password: formData?.password,
          passwordRepeat: formData?.password,
          acceptTermsOfUse: true,
          acceptPrivacyPolicy: true,
          gaClientId: get_ga_clientid(),
          url: generateUTMURLAndReferrer(hotjarUserId)?.combinedURL,
          httpReferrer:
            generateUTMURLAndReferrer(hotjarUserId)?.originalReferrer,
        };
      } else {
        data = {
          myInfoReference: Cookies.get("sid"),
          email: formData?.email,
          mobilePhone: formData?.phoneNumber,
          password: formData?.password,
          passwordRepeat: formData?.password,
          acceptTermsOfUse: true,
          acceptPrivacyPolicy: true,
          gaClientId: get_ga_clientid(),
          url: generateUTMURLAndReferrer(hotjarUserId)?.combinedURL,
          httpReferrer:
            generateUTMURLAndReferrer(hotjarUserId)?.originalReferrer,
          referralCode: formData?.referralCode,
        };
      }
      RegisterSingpassApi(data)
        .then(async (response) => {
          if (response?.token) {
            Cookies.set("auth_inv_token", response?.token);
            setLoader(false);
            navigate(ROUTES.EMAIL_VERIFICATION);
            showMessageWithCloseIcon(
              "We have sent verification email please check!"
            );
            getUser().then(async (profileResponse) => {
              trackEvent("Investor: registered", {
                user_id: profileResponse?.number,
              });
              window?.dataLayer?.push({
                event: "singpass-success",
                user_id: profileResponse?.number,
              });
            });
            const eventData = {
              gaClientId: get_ga_clientid(),
              action: "potentialInvestor",
              category: "registration",
            };
            eventsApi(eventData);
          } else {
            if (Object.values(response?.fieldErrors).length > 0) {
              setLoader(false);
              if (
                response?.fieldErrors?.referralCode !== undefined &&
                response?.fieldErrors?.email !== undefined
              ) {
                setReferralCodeValid(true);
                setEmailValid(true);
              }
              if (response?.fieldErrors?.referralCode) {
                setReferralCodeValid(true);
              } else if (response?.fieldErrors?.email) {
                setEmailValid(true);
              }
            }
          }
        })
        .catch((error) => {
          console.error("Error during Singpass Register:", error);
          setLoader(false);
        });
    }
  };

  const handleOpenTermsPdf = () => {
    setTermsPdf(true);
  };
  const handleCloseTermsPdf = () => {
    setTermsPdf(false);
  };

  const isDisabled = singPassUser === undefined ? false : true;

  const handleChangeDateOfBirth = async (date, dateString) => {
    const isoDate = date
      ? moment(dateString, "DD/MM/YYYY").utc(true).toISOString()
      : null;

    setFormData({
      ...formData,
      dob: isoDate,
    });
  };

  return (
    <AuthLayout>
      {singPassUser !== undefined ? (
        <div className="sb-onboarding-form-container">
          <h2 className="sb-text-align-start m-0 fw-600">
            Create account with Singpass
          </h2>
          <h3 className="sb-text-align-start fw-600">Information</h3>
          <div className="sb-login-form">
            <Row gutter={16}>
              <Col
                className="gutter-row mb-10"
                xs={{ span: 24 }}
                md={{ span: formData?.marriedName ? 12 : 24 }}
              >
                <div>
                  <InputDefault
                    disabled={isDisabled}
                    value={formData?.firstName}
                    name="firstName"
                    type="input"
                    validationState={setValidationErrors}
                    focusing={validationErrors?.firstName}
                    onChange={({ target }) =>
                      setFormData({ ...formData, firstName: target.value })
                    }
                    required={true}
                    errorMsg={"First name is required"}
                  />
                </div>
              </Col>
              {formData?.marriedName ? (
                <Col
                  className="gutter-row mb-10"
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                >
                  <div>
                    <InputDefault
                      disabled={isDisabled}
                      value={formData?.marriedName}
                      name="marriedName"
                      type="input"
                      validationState={setValidationErrors}
                      focusing={validationErrors?.marriedName}
                      onChange={({ target }) =>
                        setFormData({ ...formData, marriedName: target.value })
                      }
                      required={true}
                      errorMsg={"marriedName is required"}
                    />
                  </div>
                </Col>
              ) : null}

              <Col
                className="gutter-row mb-10"
                xs={{ span: 12 }}
                md={{ span: 8 }}
              >
                <div>
                  <SelectDefault
                    disabled={isDisabled}
                    value={formData?.sex}
                    defaultValue={formData?.sex}
                    validationState={validationErrors?.sex}
                    MyValue={formData?.sex}
                    placeholder="Select a gender"
                    data={[
                      { key: "MALE", value: "MALE" },
                      { key: "FEMALE", value: "FEMALE" },
                    ]}
                    onChange={(value, key) => {
                      setFormData({ ...formData, sex: key?.value });
                    }}
                    required={true}
                    errorMsg={"Please select a gender."}
                  />
                </div>
              </Col>
              <Col
                className="gutter-row mb-10"
                xs={{ span: 12 }}
                md={{ span: 8 }}
              >
                <div>
                  <DatePicker
                    disabled={isDisabled}
                    style={{ width: "100%" }}
                    className="sb-input singpass-datepicker"
                    focusing={validationErrors?.dob}
                    validationState={setValidationErrors}
                    placeholder="DD/MM/YYYY"
                    onChange={handleChangeDateOfBirth}
                    name="dob"
                    required={true}
                    errorMsg={"Enter Date of birth"}
                    value={formData?.dob ? dayjs(formData.dob) : null}
                    disabledDate={disabledDate}
                    format="DD/MM/YYYY"
                  />
                </div>
              </Col>
              <Col
                className="gutter-row mb-10"
                xs={{ span: 12 }}
                md={{ span: 8 }}
              >
                <div>
                  <InputDefault
                    disabled={isDisabled}
                    value={formData?.nricFin}
                    name="nricFin"
                    type="input"
                    validationState={setValidationErrors}
                    focusing={validationErrors?.nricFin}
                    onChange={({ target }) =>
                      setFormData({ ...formData, nricFin: target.value })
                    }
                    required={true}
                    errorMsg={"nricFin is required"}
                  />
                </div>
              </Col>
              <Col
                className="gutter-row mb-10"
                xs={{ span: 12 }}
                md={{ span: 8 }}
              >
                <div>
                  <SelectDefault
                    disabled={isDisabled}
                    defaultValue={formData?.countryOfBirth}
                    validationState={validationErrors?.countryOfBirth}
                    MyValue={formData?.countryOfBirth}
                    data={countryList}
                    placeholder="Select country"
                    onChange={(value, key) => {
                      setFormData({
                        ...formData,
                        countryOfBirth: key?.value,
                      });
                    }}
                    value={formData?.countryOfBirth}
                    required={true}
                    errorMsg={"Enter country of birth"}
                  />
                </div>
              </Col>
              <Col
                className="gutter-row mb-10"
                xs={{ span: 12 }}
                md={{ span: 8 }}
              >
                <div>
                  <SelectDefault
                    disabled={isDisabled}
                    value={formData?.nationality}
                    defaultValue={formData?.nationality}
                    validationState={validationErrors?.nationality}
                    MyValue={formData?.nationality}
                    data={countryList}
                    placeholder="Select country"
                    onChange={(value, key) => {
                      setFormData({
                        ...formData,
                        nationality: key?.value,
                      });
                    }}
                    required={true}
                    errorMsg={"Select Nationality"}
                  />
                </div>
              </Col>
              {formData?.residentialStatus ? (
                <Col
                  className="gutter-row mb-10"
                  xs={{ span: 12 }}
                  md={{ span: 8 }}
                >
                  <div>
                    <InputDefault
                      disabled={isDisabled}
                      name="residentialStatus"
                      placeholder="Residential status"
                      value={formData?.residentialStatus}
                      type="input"
                      validationState={setValidationErrors}
                      focusing={validationErrors?.residentialStatus}
                      onChange={({ target }) =>
                        setFormData({
                          ...formData,
                          residentialStatus: target.value,
                        })
                      }
                      required={true}
                      errorMsg={"residentialStatus is required"}
                    />
                  </div>
                </Col>
              ) : null}

              {formData?.passType ? (
                <Col
                  className="gutter-row mb-10"
                  md={{ span: 12 }}
                  xs={{ span: 24 }}
                >
                  <div>
                    <InputDefault
                      disabled={isDisabled}
                      name="passType"
                      placeholder="Pass Type"
                      value={formData?.passType}
                      type="input"
                      validationState={setValidationErrors}
                      focusing={validationErrors?.passType}
                      onChange={({ target }) =>
                        setFormData({ ...formData, passType: target.value })
                      }
                      required={true}
                      errorMsg={"passType is required"}
                    />
                  </div>
                </Col>
              ) : null}

              {formData?.passExDate ? (
                <Col
                  className="gutter-row mb-10"
                  md={{ span: 12 }}
                  xs={{ span: 24 }}
                >
                  <div>
                    <DatePicker
                      disabled={true}
                      style={{ width: "100%" }}
                      className="sb-input singpass-datepicker"
                      focusing={validationErrors?.passExDate}
                      validationState={setValidationErrors}
                      placeholder="DD/MM/YYYY"
                      name="passExDate"
                      value={
                        formData?.passExDate ? dayjs(formData.passExDate) : null
                      }
                      disabledDate={disabledDate}
                      format="DD/MM/YYYY"
                    />
                  </div>
                </Col>
              ) : null}

              {formData?.registeredAddress ? (
                <Col className="gutter-row mb-10" span={24}>
                  <div>
                    <InputDefault
                      disabled={isDisabled}
                      value={formData?.registeredAddress}
                      name="registeredAddress"
                      placeholder="Registered address"
                      type="input"
                      validationState={setValidationErrors}
                      focusing={validationErrors?.registeredAddress}
                      onChange={({ target }) =>
                        setFormData({
                          ...formData,
                          registeredAddress: target.value,
                        })
                      }
                      required={true}
                      errorMsg={"registeredAddress is required"}
                    />
                  </div>
                </Col>
              ) : null}

              {formData?.assemleIncome ? (
                <Col className="gutter-row mb-10" span={24}>
                  <div>
                    <InputDefault
                      disabled={isDisabled}
                      value={formData?.assemleIncome}
                      name="assemleIncome"
                      placeholder="IRAS Assessable Income (Latest Year)"
                      type="input"
                      validationState={setValidationErrors}
                      focusing={validationErrors?.assemleIncome}
                      onChange={({ target }) =>
                        setFormData({
                          ...formData,
                          assemleIncome: target.value,
                        })
                      }
                      required={true}
                      errorMsg={"assessable Income is required"}
                    />
                  </div>
                </Col>
              ) : null}
              <Col className="gutter-row mb-10" span={24}>
                {formData?.phoneNumber ? (
                  <PhoneInput
                    className={`sb-phone-field ${mobileNumberClass}`}
                    value={formData && formData?.phoneNumber}
                    onChange={handlePhoneChange}
                  />
                ) : (
                  <div>
                    <PhoneInput
                      className={`sb-phone-field ${mobileNumberClass}`}
                      placeholder="+91 12345-67890"
                      country={countryCode && countryCode}
                      value={formData && formData?.phoneNumber}
                      onChange={handlePhoneChange}
                      enableSearch
                      onBlur={handleBlur}
                    />
                    <span className="phone-error-msg">
                      {mobileNumberClass === "sb-phone-empty"
                        ? "Please enter mobile number"
                        : ""}
                    </span>
                  </div>
                )}
              </Col>
              <Col className="gutter-row mb-10" span={24}>
                <div className={emailValid === true ? "referral-error" : ""}>
                  <InputDefault
                    value={formData?.email}
                    name="email"
                    placeholder="Enter email"
                    type="input"
                    validationState={setValidationErrors}
                    focusing={validationErrors?.email}
                    onChange={({ target }) => {
                      setFormData({ ...formData, email: target.value });
                      setEmailValid(false);
                    }}
                    required={true}
                    errorMsg={"email is required"}
                  />
                </div>
              </Col>
              <Col className="gutter-row mb-10" span={24}>
                <div ref={hintRef}>
                  <Input.Password
                    name="password"
                    placeholder="Password"
                    style={{ height: 40, borderRadius: "12px" }}
                    type="password"
                    onFocus={() => setShowHint(true)}
                    onChange={handlePasswordChange}
                    status={noPass && "error"}
                    required={true}
                  />
                  {showHint && (
                    <div
                      className={`password-hint ${showHint ? "show" : ""}`}
                      style={{
                        padding: "10px",
                        background: "#f9f9f9",
                        borderRadius: "8px",
                        marginTop: "8px",
                        fontSize: "12px",
                        display: showHint ? "block" : "none",
                      }}
                    >
                      {/* <p style={{ marginBottom: "4px", fontWeight: 500 }}>
                                              Password must include:
                                            </p> */}
                      <ul
                        style={{
                          paddingLeft: "16px",
                          listStyle: "none",
                          textAlign: "left",
                          fontWeight: 500,
                        }}
                      >
                        {[
                          { key: "length", text: "At least 10 characters" },
                          {
                            key: "lowercase",
                            text: "At least one lowercase letter",
                          },
                          {
                            key: "uppercase",
                            text: "At least one uppercase letter",
                          },
                          {
                            key: "number",
                            text: "At least one numeric character",
                          },
                          {
                            key: "special",
                            text: "At least one special character",
                          },
                        ].map(({ key, text }) => (
                          <li
                            key={key}
                            style={{
                              color: criteria[key] ? "green" : "#939393",
                            }}
                          >
                            {criteria[key] ? (
                              <CheckCircleOutlined style={{ color: "green" }} />
                            ) : (
                              <CloseCircleOutlined
                                style={{ color: "#939393" }}
                              />
                            )}{" "}
                            {text}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {/* <Progress percent={strength} size="small" showInfo={false} />
                  <div className="sb-text-align-start mb-16">
                    <small style={{ fontSize: 10, color: "#999" }}>
                      Use at least 10 characters, including 1 uppercase, 1 lower
                      case, 1 special character, and 1 number
                    </small>
                  </div> */}
                  {/* {noPass && (
                    <label
                      className="error-msg"
                      style={{
                        display: "block",
                        marginTop: "-8px",
                        marginBottom: 12,
                      }}
                    >
                      Use at least 10 characters, including 1 uppercase, 1 lower
                      case, 1 special character, and 1 number
                    </label>
                  )} */}
                </div>
              </Col>
              {/* <Col className="gutter-row" span={12}>
                <div>
                  <Input.Password
                    name="password"
                    placeholder="Confirm Password"
                    type="password"
                    style={{ height: 40, borderRadius: "12px" }}
                    validationState={setValidationErrors}
                    focusing={validationErrors?.cPassword}
                    onChange={({ target }) => {
                      setCPassErr(false);
                      setFormData({ ...formData, cPassword: target.value });
                      if (target.value !== formData?.password) {
                        setCPassErr(true);
                        setValidationErrors({
                          ...validationErrors,
                          cPassword: false,
                        });
                      }
                    }}
                    status={cPassErr && "error"}
                    onBlur={() => {
                      setCPassErr(false);
                      if (formData?.password !== formData?.password) {
                        setCPassErr(true);
                      }
                    }}
                    required={true}
                  />
                  {cPassErr && (
                    <label
                      className="error-msg"
                      style={{ display: "block", marginTop: "1px" }}
                    >
                      Passwords don't match!
                    </label>
                  )}
                </div>
              </Col> */}
              <Col className="gutter-row mb-10" span={24}>
                <div className={referralCodeValid === true && "referral-error"}>
                  <InputDefault
                    name="ReferralCode"
                    placeholder="Referral code"
                    type="input"
                    validationState={setValidationErrors}
                    focusing={validationErrors?.referralCode}
                    onChange={({ target }) => {
                      setFormData({ ...formData, referralCode: target.value });
                      setReferralCodeValid(false);
                    }}
                    required={false}
                  />
                </div>
              </Col>
              <Col className="gutter-row mb-10" span={24}>
                <div className="sb-text-align-start">
                  By continuing you agree to Kilde’s{" "}
                  <span
                    className="cursor-pointer termsofuse"
                    style={{ color: "var(--kilde-blue)" }}
                    onClick={() => setPrivacyPdf(true)}
                  >
                    Terms of Use
                  </span>{" "}
                  and{" "}
                  <span
                    className="cursor-pointer termsofuse"
                    style={{ color: "var(--kilde-blue)" }}
                    onClick={handleOpenTermsPdf}
                  >
                    {" "}
                    Privacy Policy
                  </span>
                  {/* <Checkbox
                    className="checkbox-kilde"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCheckErr(false);
                      } else {
                        setCheckErr(true);
                      }
                      setChecked(e.target.checked);
                    }}
                    style={{ marginRight: 5 }}
                  />{" "}
                  I accept{" "}
                  <span
                    className="cursor-pointer"
                    style={{ color: "var(--kilde-blue)" }}
                    onClick={() => setPrivacyPdf(true)}
                  >
                    Terms of use
                  </span>{" "}
                  and
                  <span
                    className="cursor-pointer"
                    style={{ color: "var(--kilde-blue)" }}
                    onClick={handleOpenTermsPdf}
                  >
                    {" "}
                    Privacy Policy
                  </span> */}
                </div>
                {/* {noCheckErr && (
                  <label
                    className="error-msg"
                    style={{
                      display: "block",
                      marginTop: "-8px",
                      marginBottom: 12,
                    }}
                  >
                    Please accept Privacy Policy.
                  </label>
                )} */}
              </Col>
            </Row>
          </div>
          <div>
            <div className="mb-20 mt-10">
              <ButtonDefault
                title="Create account"
                onClick={HandleCreateUser}
                loading={loader}
                style={{ width: "100%" }}
              />
            </div>
            <div>
              <p>
                Already have an account?
                <Link
                  to={ROUTES.LOGIN}
                  className="cursor-pointer text-decoration-none   ml-5"
                  style={{ color: "var(--kilde-blue)" }}
                >
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <SingpassLoader />
      )}

      <Modal
        className="sb-pdf-modal"
        centered
        open={termsPdf}
        onCancel={handleCloseTermsPdf}
        width={1000}
        footer={null}
      >
        <iframe
          className="mt-20"
          src={`${PrivacyPdf}#toolbar=0`}
          width="100%"
          height="500px"
          title="PDF Viewer"
        />
      </Modal>

      <Modal
        className="sb-pdf-modal"
        centered
        open={privacyPdf}
        onCancel={() => setPrivacyPdf(false)}
        width={1000}
        footer={null}
      >
        <iframe
          className="mt-20"
          src={TermsOfUse}
          width="100%"
          height="500px"
          title="PDF Viewer"
        />
      </Modal>
    </AuthLayout>
  );
};

export default SingpassRegisterPage;
