import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";

const VideoCarousel = () => {
  const videos = [
    {
      url: "https://www.youtube.com/embed/EuNPk8rzFb4",
      title: "Kilde platform introduction tour",
    },
    {
      url: "https://www.youtube.com/embed/ydao0Vj3ioI",
      title: "Kilde platform update",
    },
    {
      url: "https://www.youtube.com/embed/qZGP8RxNRfs",
      title: "Kilde's Investor Eligibility Explained",
    },
    {
      url: "https://www.youtube.com/embed/73OrYhI149A",
      title: "January 2025 Highlights",
    },
    {
      url: "https://www.youtube.com/embed/-xmONFFBESU",
      title: "Kilde Pitch on MENA Deal Days",
    },
  ];

  return (
    <div
      style={{
        overflow: "hidden",
      }}
    >
      <h2
        style={{
          fontSize: "20px",
          fontWeight: "600",
          marginBottom: "16px",
          marginTop: "0px",
        }}
      >
        About Kilde
      </h2>
      <OwlCarousel
        className="owl-theme"
        margin={10}
        nav={true}
        dots={false}
        items={3}
        loop={false}
        responsive={{
          320: { items: 1 },
          560: { items: 2 },
          992: { items: 3 },
        }}
        style={{ maxWidth: "100%" }}
      >
        {videos.map((video, index) => (
          <div
            key={index}
            style={{
              borderRadius: "8px",
              overflow: "hidden",
              transition: "transform 0.3s ease-in-out",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "200px",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <iframe
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px",
                }}
                src={video.url}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div style={{ padding: "8px" }}>
              <h3 style={{ fontSize: "14px", fontWeight: "600" }}>
                {video.title}
              </h3>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default VideoCarousel;
